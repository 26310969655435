import React from 'react'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css'
import { RiArrowDownSFill } from 'react-icons/ri'
import styled from 'styled-components'

const DataTableStyled = styled.div`
.dlink {
  text-decoration: none !important;
  color: #03a9f3 !important;
}

.data-table-extensions-filter {
  border: 1px solid lightgray;
  border-radius: 50px;
  padding: 3px 5px;
  width: 70%;
}

.first {
  padding-right: 10px !important;
}

.filter-text {
  border: none !important;
  width: 80%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
`

const sortIcon = <RiArrowDownSFill />

const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
}

function DataTableBase(props) {
  const { columns, data } = props
  const tableData = {
    columns,
    data,
  }

  return (
    <DataTableStyled >
      <DataTableExtensions {...tableData}>
        <DataTable
          pagination
          sortIcon={sortIcon}
          dense
          paginationComponentOptions={paginationComponentOptions}
          columns={columns}
          data={data}
          subHeader
          persistTableHead
          highlightOnHover
          {...props}
        />
      </DataTableExtensions>
    </DataTableStyled>
  )
}

export default DataTableBase
