
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "layouts/Common/Breadcrumb"

// actions
import { editProfile, resetProfileFlag } from "store/actions"

const UserProfile = props => {
  const dispatch = useDispatch()

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
    user: state.Login.user
  }))

  const [user, setUser] = useState({})

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"))
      
      setUser(obj.user)
     
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  function handleValidSubmit(event, values) {
    dispatch(editProfile(values))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Perfil | SAP Filial Santa Fe</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Dashboard" breadcrumbItem="Perfil" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <Media>
                    <div className="ms-3">
                      <img
                        src={process.env.PUBLIC_URL + '/assets/images/logotipo_sap.jpg'}
                        alt="user avatar"
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>{user.last_name + ', ' + user.first_name}</h5>
                        <p className="mb-1">Username: {user.username}</p>
                        <p className="mb-1">Email: {user.email}</p>
                        <p className="mb-0">Id no: #{user.id}</p>
                        <p className="mb-0">Role: #{user.role}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Cambiar Nombre y Apellido</h4>

          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <div className="form-group">
                  <AvField
                    name="name"
                    label="Nombre"
                    value={user.username}
                    className="form-control"
                    placeholder="Ingresar nombre"
                    type="text"
                    required
                  />
                  <AvField
                    name="last_name"
                    label="Apellido"
                    value={user.last_name}
                    className="form-control"
                    placeholder="Ingresar Apellido"
                    type="text"
                    required
                  />
                  <AvField name="id" value={user.id} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Actualizar datos
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
