//PROFILE
export const GET_ME = "/profile"
export const POST_EDIT_PROFILE = "/post-profile"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/users"
export const ADD_NEW_USER = "/users"
export const UPDATE_USER = "/users"
export const DELETE_USER = "/users"
export const UPDATE_ROLE = "/users"
export const CHANGE_ACTIVO = "/users"