export const colors = {

    bg1: "#182B49",
    bg2: "#F6F9FF",
    gr_bg: "linear-gradient(90deg, #b0a478 0%, #a2935f 100%)",
    gr_bg2: "linear-gradient(90deg, #a2935f 0%, #b0a478 100%)",
    copy_bg: "#122340",
    blue: "#5f6ea2",
    brown: "#b0a478",
    brown2: "#d9cb68",
    green: "#b0a478", 
    green2: "#a2935f",
    red: "#ff6f6f",
    purple: "#84479c",
    yellow: "#fbab19",
    black1: "#182B49",
    black2: "#444444",
    white: "#ffffff",
    text1: "#555555",
    text2: "#666666",
    text3: "#969696",
    text4: "#aaaaaa",
    text5: "#cccccc",
    text6: "#ffffff",
    border1: "#eeeeee",
    border2: "#3e3e3e",
    border3: "#dddddd",
    footer1: "#1a1b25",
    footer2: "#16171f",
    ftext: "#8D8E92",

}

export const fonts = {

    roboto: "'Roboto', sans-serif",
    poppins: "'Poppins', sans-serif",

}