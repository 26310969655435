import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_USERS,
  GET_USER_PROFILE,
  ADD_NEW_USER,
  DELETE_USER,
  UPDATE_USER
} from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
} from "./actions"

//Include Both Helper File with needed methods
import UsersService from "services/users/users.service"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

function* fetchUsers() {
  try {
    const response = yield call(UsersService.getUsers)
    if (response.status === 200) {
      yield put(getUsersSuccess(response.data.data))
    }
  } catch (error) {
    yield put(getUsersFail(error.response))
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(UsersService.getMe)
    yield put(getUserProfileSuccess(response.data))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* onAddNewUser({ payload: { user, history } }) {
  try {
    const response = yield call(UsersService.addNewUser, user)
    yield put(addUserSuccess(response.data))
    toast.success(`El Usuario ${response.data.data.username} actualizado satisfactoriamente.`)
    history.push('/users')
  } catch (error) {
    yield put(addUserFail(error.response))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error. Intente nuevamente más tarde.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* onUpdateUser({ payload: { user, history, id } }) {
  try {
    const response = yield call(UsersService.updateUser,id, user)
    yield put(updateUserSuccess(response.data))
    history.push('/users')
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: { userId, history} }) {
  try {
    const response = yield call(UsersService.deleteUser, userId)
    yield put(deleteUserSuccess(response.data))
    history.push('/users')
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default usersSaga
