/* BANNERS */
export const GET_BANNERS = "GET_BANNERS"
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS"
export const GET_BANNERS_FAIL = "GET_BANNERS_FAIL"

export const ADD_NEW_BANNER = "ADD_NEW_BANNER"
export const ADD_BANNER_SUCCESS = "ADD_BANNER_SUCCESS"
export const ADD_BANNER_FAIL = "ADD_BANNER_FAIL"

export const UPDATE_BANNER = "UPDATE_BANNER"
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS"
export const UPDATE_BANNER_FAIL = "UPDATE_BANNER_FAIL"

export const DELETE_BANNER = "DELETE_BANNER"
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS"
export const DELETE_BANNER_FAIL = "DELETE_BANNER_FAIL"

export const GET_BANNER_PROFILE = "GET_BANNER_PROFILE"
export const GET_BANNER_PROFILE_SUCCESS = "GET_BANNER_PROFILE_SUCCESS"
export const GET_BANNER_PROFILE_FAIL = "GET_BANNER_PROFILE_FAIL"

export const ADD_IMAGE_BANNER = "ADD_IMAGE_BANNER"
export const ADD_IMAGE_BANNER_SUCCESS = "ADD_IMAGE_BANNER_SUCCESS"
export const ADD_IMAGE_BANNER_FAIL = "ADD_IMAGE_BANNER_FAIL"

export const UPDATE_STATUS_BANNER = "UPDATE_STATUS_BANNER"
export const UPDATE_STATUS_BANNER_SUCCESS = "UPDATE_STATUS_BANNER_SUCCESS"
export const UPDATE_STATUS_BANNER_FAIL = "UPDATE_STATUS_BANNER_FAIL"