import React from "react";
import { Redirect } from "react-router-dom";

// Authentication pages
import Login from "pages/account/Login";
import Logout from "pages/account/Logout";
import Register from "pages/account/Register";

import HomeOne from "layouts/Landing";
// import HomeTwo from "pages/home/HomeTwo";
import Gallery from "pages/gallery/Gallery";
import About from "pages/about/About";

import Events from "pages/events/Events";
import EventDetails from "pages/events/EventsDetails";
import BlogGrid from "pages/blog/BlogGrid";
import BlogDetails from "pages/blog/BlogDetails";
import Contact from "pages/contact/Contact";
import Faq from "pages/faq/Faq";
import PageNotFound from "pages/404/PageNotFound";
import Niveles from "pages/niveles/Niveles";
import NivelInicial from "pages/niveles/NivelInicial";
import NivelPrimario from "pages/niveles/NivelPrimario";
import NivelSecundario from "pages/niveles/NivelSecundario";
// 

const publicRoutes = [
  // Authentication
  { path: '/login', component: Login },
  { path: "/logout", component: Logout },
  { path: '/registration', component: Register },
  // Public pages
  // INSTITUCIONAL
  { path: '/about', component: About },
  { path: '/gallery', component: Gallery },
  { path: '/faq', component: Faq },
  { path: '/contact', component: Contact },
  // NOTICIAS
  { path: '/noticias/:slug', exact:true, component: BlogDetails },
  { path: '/noticias', component: BlogGrid },
  // NIVELES
  { path: '/niveles/inicial', component: NivelInicial },
  { path: '/niveles/primario', component: NivelPrimario },
  { path: '/niveles/secundario', component: NivelSecundario },
  { path: '/niveles', component: Niveles },
  // EVENTOS
  { path: '/eventos/:slug', exact:true, component: EventDetails },
  { path: '/eventos', component: Events },
  // { path: '/home-two', component: HomeTwo },
  { path: '/home', component: HomeOne },
  
  { path: '/', exact: true, component: ()=> <Redirect to="/home" /> },
  { path: '/**', component: PageNotFound },
];

export default publicRoutes;