import Breadcrumb from 'layouts/Common/Breadcrumb'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Media,
} from 'reactstrap'
import MetaTags from "react-meta-tags"

export const UsersOverview = props => {
  const { users } = useSelector(state => state.Users)
  const { id } = useParams()
  const user = users.find(user => user.id === Number(id))

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Perfil | SAP Filial Santa Fe</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Dashboard" breadcrumbItem="Perfil" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Media>
                    <div className="ms-3">
                      <img
                        src={process.env.PUBLIC_URL + '/assets/images/logotipo_sap.jpg'}
                        alt="user avatar"
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>{user.last_name + ', ' + user.first_name}</h5>
                        <p className="mb-1">Username: {user.username}</p>
                        <p className="mb-1">Email: {user.email}</p>
                        <p className="mb-0">Id no: #{user.id}</p>
                        <p className="mb-0">Role: #{user.role}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          
        </Container>
      </div>
    </>
  )
}

export default UsersOverview
