import React, { useEffect } from 'react'
import { Container, Row, Card, Button, Spinner } from 'reactstrap'
import MetaTags from 'react-meta-tags'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { AiFillDelete, AiFillEdit } from 'react-icons/ai'
import Swal from 'sweetalert2'

import { getBanners, deleteBanner } from 'store/actions'
//Import Breadcrumb
import Breadcrumbs from 'layouts/Common/Breadcrumb'
import DataTableBase from '../components/DataTableBase'
import BadgeStatus from '../components/BadgeStatus'
import { changeStatusBanner } from 'store/actions'
// import cloudService from 'services/cloud.service'

const BannersList = props => {
  const { match, history } = props
  const { path, url } = match

  const dispatch = useDispatch()
  let banners = useSelector(state => state.Banners.banners)
  const { loading, error } = useSelector(state => state.Banners)

  useEffect(() => {
    dispatch(getBanners())
  }, [])

  useEffect(() => error && toast.error(error), [error])

  if (loading) {
    return (
      <Spinner color="primary" size="">
        Cargando...
      </Spinner>
    )
  }
 
  const Datas = banners.length ? banners : []

  const handelDelete = (id) => {
    Swal.fire({
      title: `¿Está seguro de querer eliminiar el registro ${id}?`,
      text: 'Luego no se podra revertir la acción!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, elimiar!',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteBanner(Number(id), history))
      }
    })
    
  }

  
  const handleStatusBadge = (data) => {
  
    const handleStatus = id => {
      dispatch(changeStatusBanner(data, history, Number(id)))
      
    }

    switch (data.status) {
      case 'PUBLISHED':
        return <Button color="success" onClick={() => handleStatus(data.id)} className='btn-rounded'>
        <BadgeStatus 
          title='Publicado'
          path={`${path}/${data.id}/unpublish`}
          color="success"
        />
        </Button>
      case 'UNPUBLISHED':
        return <Button color="secondary" onClick={() => handleStatus(data.id)} className='btn-rounded'>
        <BadgeStatus
          title='Archivo'
          path={`${path}/${data.id}/publish`}
          color="secondary"
        />
        </Button>
      case 'DRAFT':
      default:
        return <Button color="warning" onClick={() => handleStatus(data.id)} className='btn-rounded' key={data.id}>

        <BadgeStatus 
          title='Borrador'
          path={`${path}/${data.id}/publish`}
          color="warning"
        /> 
        </Button>
    }

  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: false,
      right: true,
    },
    {
      name: 'Título',
      cell: row => <Link className="dlink" to={`${path}/banners/${row.id}/edit`}>
          {row.title}
      </Link>,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Subtítulo',
      selector: row => row.subtitle,
      wrap: true,
		  sortable: false,
		  format: row => `${row.subtitle.slice(0, 150)}...`,
    },
    {
      name: 'Banner',
      sortable: false,
      cell: row => <Link to={`${path}/banners/${row.id}/edit`}>
        <img height="84px" width="150px" alt={row.title} src={row.image_url} />
        </Link>,
      grow:3,
      center:true,
    },
    {
      name: 'Estado',
      button: true,
      cell: row => (
        <>
          {handleStatusBadge(row)}
        </>
      )
    },
    {
      name: 'Orden',
      sortable: false,
      selector: row => row.sorted,
      right: true,
    },
    {
      name: 'Acciones',
      cell: row => (
        <>
        <Link to={`${path}/banners/${row.id}/edit`}>
          <Button color="primary" size="lg">
            <AiFillEdit />
          </Button>
        </Link>
        <Button color="danger" size="lg" onClick={() => handelDelete(row.id, row.image_id)}>
          <AiFillDelete />
        </Button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Banners | Colegio NS Guadalupe - Santa Fe</title>
        </MetaTags>
        <Container fluid>
         <div className="clearfix" style={{ padding: '.5rem' }}>
            {url !== path && (
              <Link to={`${path}/banners`}>
                <Button color="secondary" className="float-left">
                  Volver
                </Button>
              </Link>
            )}
            <Link to={`${path}/banners/create`}>
              <Button color="primary" className="float-right">
                Nuevo
              </Button>
            </Link>
          </div>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Banners" breadcrumbItem="Lista de banners" />
         
          <Row>
            <div className="col">
              <Card className="shadow">
                <DataTableBase columns={columns} data={Datas} />
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BannersList
