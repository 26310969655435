import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import StickyMenu from './common/StickyMenu'
import MobileMenu from './common/MobileMenu'
import { Styles } from './styles/headerTwo'
import Logo from './common/Logo'
import Menu from './common/Menu'

class HeaderTwo extends Component {
  render() {
    return (
      <Styles>
        {/* Topbar 2 */}
        <section className="top-bar2">
          <Container>
            <Row>
              <Col lg="8" md="9">
                <div className="bar-left">
                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                      <i className="las la-phone"></i>(0342) 4525489
                    </li>
                    <li className="list-inline-item">
                      <i className="las la-envelope"></i>
                      contacto@colegionsguadalupe.edu.ar
                    </li>
                    <li className="list-inline-item">
                      <i className="las la-map-marker"></i>9 de Julio 2558, 3000
                      SF.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="4" md="3">
                <div className="bar-right d-flex justify-content-end">
                  <ul className="list-unstyled list-inline bar-social">
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + '/'}>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + '/'}>
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + '/'}>
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + '/'}>
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>

                  {/* <ul className="list-unstyled list-inline sidebar-button">
                                        <li className="list-inline-item nav-item side-box">
                                            <Sidebar />
                                        </li>
                                    </ul> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Logo Area 2 */}
        <section className="logo-area2">
          <Container>
            <Row>
              <Col md="3">
                <Logo />
              </Col>
              <Col md="9">
                <div className="menu-box d-flex justify-content-end">
                  <Menu />
                  {/* <div className="search-box">
                                        <Search />
                                    </div> */}
                  <div className="apply-btn">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScNz5qeK7_x0j2hJlwWpUfv8cPIqod0yfElHrAZxdvSdVkkVQ/viewform" target='blank'>
                      <i className="las la-clipboard-list"></i>Ingresantes
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Sticky Menu */}
        <StickyMenu />

        {/* Mobile Menu */}
        <MobileMenu />
      </Styles>
    )
  }
}

export default HeaderTwo
