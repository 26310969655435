import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import HeaderTwo from 'components/HeaderTwo'
import { BreadcrumbBox } from 'components/common/Breadcrumb'
import FooterTwo from 'components/FooterTwo'
import { Styles } from './styles/account'

import { loginUser, logoutUser } from 'store/actions'

function Login() {
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  })
//   const [submitted, setSubmitted] = useState(false)
  const { email, password } = inputs
  // const loggingIn = useSelector(state => state.Login.isLoggedIn)
  const dispatch = useDispatch()
  const location = useLocation()
  let history = useHistory();

  useEffect(() => {
    dispatch(logoutUser())
  }, [])

  function handleChange(e) {
      const { name, value } = e.target;
      setInputs(inputs => ({
          ...inputs,
          [name]: value
      }))
  }

  function handleSubmit(e) {
    e.preventDefault()
    
    if (email && password) {
      const { from } = location.state || { from: { pathname: '_/dashboard' } }
      dispatch(loginUser(inputs, from, history))
    }
  }

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper login-page">
        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Ingreso" />

        {/* Login Area */}
        <section className="login-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="login-box">
                  <div className="login-title text-center">
                    <h3>Ingreso</h3>
                  </div>
                  <AvForm
                    id="form_login"
                    className="form"
                    onValidSubmit={handleSubmit}
                  >
                      
                    
                      <AvField
                        id="login_user"
                        name="email"
                        label="Email"
                        placeholder="intoducir un email"
                        type="email"
                        required
                        onChange={handleChange}
                        errorMessage="Debe introducir un email correcto!"
                      />
                    
                    
                      <AvField
                        id="login_password"
                        name="password"
                        label="Clave"
                        type="password"
                        required
                        onChange={handleChange}
                        placeholder="Introducir la clave"
                        errorMessage="Debe introducir una clave!"
                      />
                    <button type="submit" >
                      Ingresar
                    </button>

                    <div className="save-forget-password d-flex justify-content-between">
                      <div className="forget-password">
                        <Link to={process.env.PUBLIC_URL + '/forgetpass'}>
                          ¿Olvidó su clave?
                        </Link>
                      </div>
                      <div className="not_account-btn text-center">
                        <p>
                          ¿Aún no tiene usuario?{' '}
                          <Link to={process.env.PUBLIC_URL + '/registration'}>
                            Click aquí
                          </Link>
                        </p>
                      </div>
                    </div>
                  </AvForm>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <FooterTwo />
      </div>
    </Styles>
  )
}

export default Login
