import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router'
import { connect } from 'react-redux'


const AppRoute = ({
  component: Component,
  layout:Layout,
  isAuthProtected,
  user,
  ...rest
}) => (
  <Route
    {...rest}

    render={props =>{
      if (isAuthProtected && !sessionStorage.getItem('authUser')) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
  
)

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  paht: PropTypes.string,
  layout: PropTypes.any,
  exact: PropTypes.any,
  user: PropTypes.any,
}

const mapStateToProps = state => ({
  user: state.Login.user,
  profile: state.profile,
});

export default connect(
  mapStateToProps,null
)(AppRoute)
