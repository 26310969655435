import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
// import Search from './common/Search';
import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/header";
import Logo from "./common/Logo";
import Menu from "./common/Menu";

class Header extends Component {
    render() {
        return (
            <Styles>
                {/* Topbar */}
                <section className="top-bar">
                    <Container>
                        <Row>
                            <Col lg="6" md="5">
                                <div className="bar-left">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><i className="las la-map-marker"></i>9 de Julio 2558, 3000 SF.</li>
                                        <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/faq"}>Alguna Pregunta?</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="6" md="7">
                                <div className="bar-right d-flex justify-content-end">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item">
                                            <a href={"https://www.fic.org.ar/"} target="blank">
                                                <img 
                                                    src={process.env.PUBLIC_URL + "/assets/images/m_escudo-fic-2.webp"} 
                                                    alt="link pagina hijas inmaculada concepcion"
                                                    height={40}
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled list-inline bar-social">
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                    
                                    <ul className="list-unstyled list-inline bar-login">
                                        <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/login"}><i className="las la-user"></i>Ingresar</Link></li>
                                        <li className="list-inline-item"><a href="https://sisweb.nsg.fic.org.ar" target="blank"><i className="las la-coins"></i>Aranceles</a></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Logo Area */}
                <section className="logo-area">
                    <Container>
                        <Row>
                            <Col md="3">
                                <Logo />
                            </Col>
                            <Col md="9">
                                <div className="logo-contact-box d-flex justify-content-end">
                                    <div className="emcontact-box d-flex">
                                        <div className="box-icon">
                                            <i className="flaticon-phone-call"></i>
                                        </div>
                                        <div className="box-content">
                                            <p>Teléfonos:</p>
                                            <a href="tel:+543424525489"><span>0342-4525489</span></a><br />
                                            <a href="tel:+543424559546"><span>0342-4559546</span></a>
                                            
                                        </div>
                                    </div>
                                    <div className="emcontact-box d-flex">
                                        <div className="box-icon">
                                            <i className="flaticon-envelope"></i>
                                        </div>
                                        <div className="box-content">
                                            <p>Email:</p>
                                            <a href="mailto:contacto@colegionsguadalupe.edu.ar"><span>contacto@colegionsguadalupe.edu.ar</span></a>
                                        </div>
                                    </div>
                                    <div className="apply-btn">
                                        <a href="https://docs.google.com/forms/d/e/1FAIpQLScNz5qeK7_x0j2hJlwWpUfv8cPIqod0yfElHrAZxdvSdVkkVQ/viewform" target="blank">
                                            <i className="las la-clipboard-list"></i>Ingresantes
                                        </a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Navbar */}
                <section className="main-menu">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="main-menu-box">
                                    <div className="menu-box d-flex justify-content-between">
                                        <Menu />
                                        <ul className="nav search-cart-bar">
                                            <li className="nav-item search-box">
                                                {/* <Search /> */}
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Sticky Menu */}
                <StickyMenu />

                {/* Mobile Menu */}
                <MobileMenu />
            </Styles>
        )
    }
}

export default Header
