import {
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  ADD_COURSE_SUCCESS,
  ADD_COURSE_FAIL,
  ADD_IMAGE_COURSE_SUCCESS,
  ADD_IMAGE_COURSE_FAIL,
  UPDATE_STATUS_COURSE_SUCCESS,
  UPDATE_STATUS_COURSE_FAIL,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_FAIL,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAIL,
} from './actionTypes'

const INIT_STATE = {
  courses: [],
  error: {},
}

const courses = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
        loading: false,
      }

    case GET_COURSES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_COURSE_SUCCESS:
      return {
        ...state,
        courses: [...state.courses, action.payload],
        loading: false,
      }

    case ADD_COURSE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case ADD_IMAGE_COURSE_SUCCESS:
      return {
        ...state,
        image: [...state, action.payload],
        loading: false,
      }

    case ADD_IMAGE_COURSE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_STATUS_COURSE_SUCCESS:
      return {
        ...state,
        courses: state.courses.map(course =>
          course.id.toString() === action.payload.id.toString()
            ? { course, ...action.payload }
            : course
        ),
      }

    case UPDATE_STATUS_COURSE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_COURSE_SUCCESS:
      return {
        ...state,
        courses: state.courses.map(course =>
          course.id.toString() === action.payload.id.toString()
            ? { course, ...action.payload }
            : course
        ),
      }

    case UPDATE_COURSE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_COURSE_SUCCESS:
      return {
        ...state,
        courses: state.courses.filter(
          course => course.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_COURSE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default courses
