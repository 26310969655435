import { call, put, takeEvery, all, fork } from "redux-saga/effects"
// import { history } from "helpers/history"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

import AuthService from 'services/auth/auth.service'
import { toast } from "react-toastify"

function* loginUser({ payload: { user, from, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "api") {
      const response = yield call(AuthService.postLogin, user)
      yield put(loginSuccess(response))
    }
    history.push('_/dashboard')
    
  } catch (error) {
    let message = '';
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 404:
          message = 'Lo sentimos! La página que busca no existe.';
          break;
        case 500:
          message =
            'Lo sentimos! Algo salió mal, por favor ponte en contacto con nuuestro equipo.';
          break;
        case 401:
          message = 'Credenciales inválidas. Email o password incorrectos';
          break;
        case 422:
          message = 'Datos inválidos, verifique los datos cargados';
          break;
        default:
          message = error[1];
          break;
      }
    }
    yield put(apiError(message))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(AuthService.postLogout())
    
    yield put(logoutUserSuccess(response.data))
    toast.success('Desconexión existosa.')
  } catch (error) {
    yield put(apiError(error))
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
  yield all([
    fork(watchUserLogin), 
    fork(watchUserLogout),
  ])
}

export default authSaga
