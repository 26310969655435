import React, { useEffect } from 'react'
import { Container, Row, Card, Button, Spinner } from 'reactstrap'
import MetaTags from 'react-meta-tags'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { AiFillDelete, AiFillEdit, AiFillEye } from 'react-icons/ai'
import Swal from 'sweetalert2'

import { deleteNews, changeStatusNews } from 'store/actions'
//Import Breadcrumb
import Breadcrumbs from 'layouts/Common/Breadcrumb'
import DataTableBase from '../components/DataTableBase'
import BadgeStatus from 'pages/admin/components/BadgeStatus'

const NewsList = props => {
  const { match, history } = props
  const { path, url } = match

  const dispatch = useDispatch()
  const { loading, error } = useSelector(state => state.News)
  let news = useSelector(state => state.News.news)

  useEffect(() => error && toast.error(error), [error])

  if (loading) {
    return (
      <Spinner color="primary" size="">
        Cargando...
      </Spinner>
    )
  }

  const Datas = news.length ? news : []

  // const handleView = id => {
  //   return viewNewsModal(id)
  // }

  const viewNewsModal = row => {
    return (
      
      <div className="openbtn text-center">
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#myModal"
      >
        Detalle
      </button>
      <div className="modal" id="myModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{row.title}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-5">
                  <img src={row.image} className="img-fluid" alt={row.title} />
                </div>

                <div className="col-7">
                  <p>
                    <strong>{row.title}</strong>
                  </p>
                  <p>{row.subtitle}</p>
                  <hr />
                  <p>{row.link}</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }

  const handelDelete = id => {
    Swal.fire({
      title: `¿Está seguro de querer eliminiar el registro ${id}?`,
      text: 'Luego no se podra revertir la acción!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, elimiar!',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteNews(Number(id), history))
      }
    })
  }

  const handleStatusBadge = data => {
    const handleStatus = id => {
      dispatch(changeStatusNews(data, history, Number(id)))
    }

    switch (data.status) {
      case 'PUBLISHED':
        return (
          <Button
            color="success"
            onClick={() => handleStatus(data.id)}
            className="btn-rounded"
          >
            <BadgeStatus title="Publicado" color="success" />
          </Button>
        )
      case 'UNPUBLISHED':
        return (
          <Button
            color="secondary"
            onClick={() => handleStatus(data.id)}
            className="btn-rounded"
          >
            <BadgeStatus title="Archivo" color="secondary" />
          </Button>
        )
      case 'DRAFT':
      default:
        return (
          <Button
            color="warning"
            onClick={() => handleStatus(data.id)}
            className="btn-rounded"
            key={data.id}
          >
            <BadgeStatus title="Borrador" color="warning" />
          </Button>
        )
    }
  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: false,
      center: true,
      width: "60px",
    },
    {
      name: 'Título',
      selector: row => row.title,
      cell: row => <Link to={`${path}/news/${row.id}/edit`}>{row.title}</Link>,
      sortable: true,
    },
    {
      name: 'Categoría',
      selector: row => row.category.title,
      sortable: false,
    },
    {
      name: 'Portada',
      selector: row => row.image,
      sortable: false,
      center: true,
      grow: 3,
      cell: row => (
        <Link className="dlink" to={`${path}/news/${row.id}/edit`}>
          <img
            height="84px"
            width="150px"
            alt={row.title}
            src={row.image_url}
          />
        </Link>
      ),
    },
    {
      name: 'Estado',
      button: true,
      width: "60px",
      center: true,
      cell: row => <>{handleStatusBadge(row)}</>,
    },
    {
      name: 'Publico',
      center: true,
      selector: row => row.publico,
      sortable: false,
    },
    {
      name: 'Destacada',
      center: true,
      selector: row => row.destacado,
      sortable: false,
    },
    {
      name: 'Acciones',
      cell: row => (
        <>
          <Link to={`${path}/news/${row.id}/edit`}>
            <Button color="primary" size="lg">
              <AiFillEdit />
            </Button>
          </Link>
          <Button color="info" size="lg" onClick={() => viewNewsModal(row)}>
            <AiFillEye />
          </Button>
          <Button color="danger" size="lg" onClick={() => handelDelete(row.id)}>
            <AiFillDelete />
          </Button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Noticias | NS Guadalupe  Santa Fe</title>
        </MetaTags>
        <Container fluid>
          <div className="clearfix" style={{ padding: '.5rem' }}>
            {url !== path && (
              <Link to={`${path}`}>
                <Button color="secondary" className="float-left">
                  Volver
                </Button>
              </Link>
            )}
            <Link to={`${path}/news/create`}>
              <Button color="primary" className="float-right">
                Nuevo
              </Button>
            </Link>
          </div>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Noticias"
            breadcrumbItem="Lista de noticias"
          />

          <Row>
            <div className="col">
              <Card className="shadow">
                <DataTableBase columns={columns} data={Datas} />
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewsList
