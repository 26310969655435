import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { MetaTags } from 'react-meta-tags'
import { useDispatch, useSelector } from 'react-redux'
import Ckeditor from 'react-ckeditor-component/lib/ckeditor'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
} from 'reactstrap'

import CloudService from 'services/cloud.service'
import Breadcrumbs from 'layouts/Common/Breadcrumb'
import { addNewEvent, updateEvent } from 'store/actions'
import FileUpload from 'pages/admin/components/fileUpload/FileUpload'

const initialEventState = {
  title: '',
  // slug: '',
  fecha: new Date('dd/mm/yyyy'),
  time: '',
  location: '',
  descripcion: '',
  excerpt: '',
  publico: 0,
  destacado:0,
  status: 'DRAFT',
  link: '',
  website: '',
  email: '',
  image_url: '',
  phone: '',
  organizer: '',
  file: [],
  img_data: [],
  user_id: '',
  profcategory_id: '',
}

const EventCreate = props => {
  const { error, loading } = props
  const { id } = useParams()
  const [eventForm, setEventForm] = useState(initialEventState)
  const [editMode, setEditMode] = useState(false)
  const user = useSelector(state => state.Login.user.user)
  const events = useSelector(state => state.Events.events)
  const categories = useSelector(
    state => state.Category.categories
  )
  const {
    title,
    slug,
    fecha,
    time,
    location,
    descripcion,
    excerpt,
    publico,
    destacado,
    link,
    website,
    email,
    image_url,
    phone,
    organizer,
    status,
    user_id,
    profcategory_id,
  } = eventForm

  const updateUploadedFiles = files => {
    CloudService.imageUp(files[0], 'website/events')
      .then(res => res.json())
      .then(data => {
        const newEventform = {
          ...eventForm,
          image_url: data.secure_url,
          img_data: data,
        }
        setEventForm(newEventform)
      })
  }

  function updateContent(newContent) {
    setEventForm({ ...eventForm, descripcion: newContent })
  }

  const onChange = evt => {
    var newContent = evt.editor.getData()
    updateContent(newContent)
  }

  const afterPaste = evt => {
    var newContent = evt.editor.getData()
    updateContent(newContent)
  }
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      const singleEvent = events.find(event => event.id === Number(id))
      updateContent(singleEvent.descripcion)
      setEventForm({ ...singleEvent })
      setEditMode(true)
    } else {
      setEditMode(false)
      setEventForm({ ...initialEventState })
    }
    return () => {
      setEventForm({ ...initialEventState })
    }
  }, [id, events])

  const handleValidSubmit = (e, values) => {
    e.preventDefault()
    const allValues = { ...eventForm, ...values }

    if (editMode) {
      dispatch(updateEvent(allValues, props.history, id))
      setEditMode(false)
    } else {
      dispatch(addNewEvent(allValues, props.history))
    }
    props.history.goBack()
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>{id ? 'Editar' : 'Nuevo'} Eventos | NS Guadalupe Santa Fe</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs
          title="Eventos"
          breadcrumbItem={id ? 'Editar Evento' : 'Nuevo Evento'}
        />
        <Row>
          <Col lg="12">
            <Card>
              <CardTitle className="mb-4">
                {id ? 'Editar Evento: ' + slug : 'Crear nuevo Evento'}
              </CardTitle>
              <CardBody className="mb-4">
                <AvForm onValidSubmit={handleValidSubmit}>
                  {error ? <Alert color="danger">{error}</Alert> : null}

                  {id ? (
                    <FormGroup className="mb-4" row>
                      <Col lg="10">
                        <AvField
                          id="id"
                          name="id"
                          type="hidden"
                          className="form-control"
                          value={id || ''}
                        />
                      </Col>
                    </FormGroup>
                  ) : null}
                  <FormGroup className="mb-4" row>
                      <Col lg="10">
                        <AvField
                          id="user_id"
                          name="user_id"
                          type="hidden"
                          className="form-control"
                          value={user_id || user.id}
                        />
                      </Col>
                    </FormGroup>
                  <Row>
                    <Col lg="10">
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="title"
                          className="col-form-label col-lg-2"
                        >
                          Título
                        </Label>
                        <AvField
                          id="title"
                          name="title"
                          type="text"
                          className="form-control"
                          value={title || ''}
                          placeholder="Ingresar un título"
                          autoFocus={true}
                          required
                          errorMessage="Debe ingresar un título válido..."
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="5">
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="fecha"
                          className="col-form-label col-lg-4"
                        >
                          Fecha
                        </Label>
                        <AvField
                          id="fecha"
                          name="fecha"
                          type="date"
                          className="form-control"
                          value={fecha || ''}
                          required
                          errorMessage="Debe ingresar un fecha válida..."
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="5">
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="time"
                          className="col-form-label col-lg-4"
                        >
                          Hora
                        </Label>
                        <AvField
                          id="time"
                          name="time"
                          type="time"
                          className="form-control"
                          value={time || ''}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="10">
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="location"
                          className="col-form-label col-lg-4"
                        >
                          Lugar
                        </Label>
                        <AvField
                          id="location"
                          name="location"
                          type="text"
                          className="form-control"
                          value={location || ''}
                          placeholder="Ingresar un lugar"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      <FormGroup className="mb-3 mt-3 mt-lg-0 select2-container" row>
                        <Label
                          htmlFor="status"
                          className="col-form-label col-lg-3"
                        >
                          Estado
                        </Label>
                        <AvField
                          id="status"
                          name="status"
                          type="select"
                          className="form-select"
                          value={status || 'DRAFT'}
                          required
                          errorMessage="Debe ingresar un estado válido..."
                        >
                          <option key="1" value="DRAFT">
                            BORRADOR
                          </option>
                          <option key="2" value="PUBLISHED">
                            PUBLICADO
                          </option>
                          <option key="3" value="UNPUBLISHED">
                            ARCHIVO
                          </option>
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="publico"
                          className="col-form-label col-lg-4"
                        >
                          Público
                        </Label>

                        <AvField
                          id="publico"
                          name="publico"
                          type="checkbox"
                          className="form-control"
                          value={publico || ''}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="destacado"
                          className="col-form-label col-lg-4"
                        >
                          Destacado
                        </Label>

                        <AvField
                          id="destacado"
                          name="destacado"
                          type="checkbox"
                          className="form-control"
                          value={destacado || ''}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="5">
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="email"
                          className="col-form-label col-lg-4"
                        >
                          Email
                        </Label>
                        <AvField
                          id="email"
                          name="email"
                          type="email"
                          className="form-control"
                          value={email || ''}
                          placeholder="Ingresar una email"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="5">
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="phone"
                          className="col-form-label col-lg-4"
                        >
                          Teléfono
                        </Label>
                        <AvField
                          id="phone"
                          name="phone"
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          className="form-control"
                          value={phone || ''}
                          placeholder="Ingresar un telefono"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="5">
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="website"
                          className="col-form-label col-lg-4"
                        >
                          Website
                        </Label>
                        <AvField
                          id="website"
                          name="website"
                          type="url"
                          pattern="https://.*"
                          className="form-control"
                          value={website || ''}
                          placeholder="https://example.com"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="5">
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="link"
                          className="col-form-label col-lg-4"
                        >
                          Enlace
                        </Label>
                        <AvField
                          id="link"
                          name="link"
                          type="url"
                          pattern="https://.*"
                          className="form-control"
                          value={link || ''}
                          placeholder="https://example.com/evento"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="5">
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="organizer"
                          className="col-form-label col-lg-4"
                        >
                          Organizador
                        </Label>
                        <AvField
                          id="organizer"
                          name="organizer"
                          type="text"
                          className="form-control"
                          value={organizer || ''}
                          placeholder="Ingresar un organizador"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="5">
                      <FormGroup className="mb-3">
                        <Label
                          htmlFor="status"
                          className="mb-3 mt-3 mt-lg-0 select2-container"
                        >
                          Categoría
                        </Label>
                        <AvField
                          className="select2-selection"
                          type="select"
                          name="profcategory_id"
                          value={profcategory_id || ''}
                          required
                          errorMessage="Debe seleccionar una categoría."
                        >
                          <option key="0" value="">
                            Seleccionar Categoría
                          </option>
                          {categories?.map(data => (
                            <option key={data.id} value={data.id}>
                              {data.title}
                            </option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="10">
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="excerpt"
                          className="col-form-label col-lg-4"
                        >
                          Síntesis
                        </Label>
                        <AvField
                          id="excerpt"
                          name="excerpt"
                          type="text"
                          className="form-control"
                          value={excerpt || ''}
                          placeholder="Ingresar una síntesis"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="10">
                      <FormGroup className="mb-4">
                        <Label
                          htmlFor="descripcion"
                          className="col-form-label col-lg-2"
                        >
                          Contenido
                        </Label>

                        <Ckeditor
                          activeClass="p10"
                          content={descripcion || ''}
                          events={{
                            // "blur": (e) => onBlur(e),
                            afterPaste: e => afterPaste(e),
                            change: e => onChange(e),
                          }}
                          required={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="10">
                    <FormGroup className="mb-4" row>
                    {image_url ? (
                      <Col lg="10">
                        <img alt={title} src={image_url} />
                        <AvField
                          id="image_url"
                          name="image_url"
                          type="text"
                          className="form-control"
                          value={image_url || ''}
                        />
                      </Col>
                    ) : null}
                    <FileUpload
                      accept=".jpg,.png,.jpeg"
                      label="Imagen del Event"
                      updateFilesCb={updateUploadedFiles}
                    />
                  </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button type="submit" color="primary" disabled={loading}>
                        {!editMode ? 'Guardar' : 'Actualizar'}
                        {loading && <Spinner color="info" size="sm" />}
                      </Button>{' '}
                      <Button
                        onClick={e => props.history.goBack()}
                        color="danger"
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EventCreate
