import React, { Component } from "react"
import { Row, Col } from "reactstrap"

class Footer extends Component {
  render() {
    return (
        <footer className="footer">
          <div className="container-fluid">
            <Row>
              <Col sm={10}>{new Date().getFullYear()} © Colegio Nuestra Señora de Guadalupe - Santa Fe.</Col>
              <Col sm={2}>
                <div className="text-sm-end d-none d-sm-block">
                  JJSoft 
              </div>
              </Col>
            </Row>
          </div>
        </footer>
    )
  }
}

export default Footer;