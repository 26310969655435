import {
  GET_COURSES,
  GET_COURSES_FAIL,
  GET_COURSES_SUCCESS,
  ADD_NEW_COURSE,
  ADD_COURSE_SUCCESS,
  ADD_COURSE_FAIL,
  ADD_IMAGE_COURSE,
  ADD_IMAGE_COURSE_SUCCESS,
  ADD_IMAGE_COURSE_FAIL,
  UPDATE_STATUS_COURSE,
  UPDATE_STATUS_COURSE_SUCCESS,
  UPDATE_STATUS_COURSE_FAIL,
  UPDATE_COURSE,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_FAIL,
  DELETE_COURSE,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAIL,
} from './actionTypes'

// list
export const getCourses = () => ({
  type: GET_COURSES,
})

export const getCoursesSuccess = courses => ({
  type: GET_COURSES_SUCCESS,
  payload: courses,
})

export const getCoursesFail = error => ({
  type: GET_COURSES_FAIL,
  payload: error,
})
// end list \\

// add
export const addNewCourse = (course, history) => ({
  type: ADD_NEW_COURSE,
  payload: {
    course,
    history,
  },
})

export const addCourseSuccess = course => ({
  type: ADD_COURSE_SUCCESS,
  payload: course,
})

export const addCourseFail = error => ({
  type: ADD_COURSE_FAIL,
  payload: error,
})
// end add \\

// add IMAGE
export const addImageCourse = (image, history) => ({
  type: ADD_IMAGE_COURSE,
  payload: {
    image,
    history,
  },
})

export const addImageCourseSuccess = image => ({
  type: ADD_IMAGE_COURSE_SUCCESS,
  payload: image,
})

export const addImageCourseFail = error => ({
  type: ADD_IMAGE_COURSE_FAIL,
  payload: error,
})
// end add IMAGE\\

// UPDATE STATUS
export const changeStatusCourse = (course, history, id) => ({
  type: UPDATE_STATUS_COURSE,
  payload: {
    course,
    history,
    id,
  },
})

export const changeStatusCourseSuccess = course => ({
  type: UPDATE_STATUS_COURSE_SUCCESS,
  payload: course,
})

export const changeStatusCourseFail = error => ({
  type: UPDATE_STATUS_COURSE_FAIL,
  payload: error,
})
// end UPDATE STATUS\\

// edit
export const updateCourse = (course, history, id) => ({
  type: UPDATE_COURSE,
  payload: {
    course,
    history,
    id,
  },
})

export const updateCourseSuccess = course => ({
  type: UPDATE_COURSE_SUCCESS,
  payload: course,
})

export const updateCourseFail = error => ({
  type: UPDATE_COURSE_FAIL,
  payload: error,
})
// end edit \\

// delete
export const deleteCourse = (courseId, history) => ({
  type: DELETE_COURSE,
  payload: {
    courseId,
    history,
  },
})

export const deleteCourseSuccess = courseId => ({
  type: DELETE_COURSE_SUCCESS,
  payload: courseId,
})

export const deleteCourseFail = error => ({
  type: DELETE_COURSE_FAIL,
  payload: error,
})
// end delete \\
