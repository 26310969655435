import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"

// Crypto Redux States
import {
  GET_NEWS,
  ADD_NEW_NEWS,
  ADD_IMAGE_NEWS,
  UPDATE_STATUS_NEWS,
  DELETE_NEWS,
  UPDATE_NEWS
} from "./actionTypes"

import {
  getAllNewsSuccess,
  getAllNewsFail,
  addNewsFail,
  addNewsSuccess,
  addImageNewsFail,
  addImageNewsSuccess,
  changeStatusNewsFail,
  changeStatusNewsSuccess,
  updateNewsSuccess,
  updateNewsFail,
  deleteNewsSuccess,
  deleteNewsFail,
} from "./news.actions"

//Include Both Helper File with needed methods
import NewsServices from "services/news/news.service"
import Swal from "sweetalert2"

function* fetchAllNews() {
  try {
    const response = yield call(NewsServices.getAllNews)
    if (response.status === 200) {
      yield put(getAllNewsSuccess(response.data.data))
      
    }
  } catch (error) {
    yield put(getAllNewsFail(error.response))
  }
}

function* onAddNewNews({ payload: { news, history } }) {
  try {
    const response = yield call(NewsServices.addNewNews, news)
    if (response.status === 401) {
      Swal.fire({
        title: 'Error!',
        text: 'Su sesión ah expirado, debe loguearse nuevamente. <br /> Respuesta servidor: ' + response.data.message,
        icon: 'error',
        confirmButtonColor: '#62a086'
      })
    }
    
    if (response.status === 201) {
      yield put(addNewsSuccess(response.data.data))
      toast.success('Noticia creada satisfactoriamente.')
    }

  } catch (error) {
    yield put(addNewsFail(error))
    console.log(error.response.data.message);
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error. Intente nuevamente más tarde.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* onAddImageNews({ payload: { image, history } }) {
  console.log(image);
  try {
    const response = yield call(NewsServices.addImageNews, image)
    yield put(addImageNewsSuccess(response.data.data))
    history.push('/news')
  } catch (error) {
    yield put(addImageNewsFail(error))
  }
}

function* onCangeStatusNews({ payload: { news, history, id} }) {
  try {
    const response = yield call(NewsServices.changeStatus, id, news)
    if (response.status === 201) {
      yield put(changeStatusNewsSuccess(response.data.data))
      toast.success('Noticia actualizada satisfactoriamente.')
    }
  } catch (error) {
    yield put(changeStatusNewsFail(error))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error. Intente nuevamente más tarde.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* onUpdateNews({ payload: { news, history, id } }) {
  try {
    const response = yield call(NewsServices.updateNews,id, news)
    if (response.status === 201) {
      
      yield put(updateNewsSuccess(response.data.data))
      toast.success(`La noticia ${response.data.slug} fue actualizada satisfactoriamente.`)
    }
    
  } catch (error) {
    yield put(updateNewsFail(error))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error. Intente nuevamente más tarde.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* onDeleteNews({ payload: { newsId, history} }) {
  try {
    const response = yield call(NewsServices.deleteNews, newsId)
    if (response.status === 200) {
      yield put(deleteNewsSuccess(response.data.data))
      Swal.fire({
        title: 'Eliminado!',
        text: 'Se ha eliminado la noticia exitosamente.',
        icon: 'success',
        confirmButtonColor: '#62a086'
      })
    }
  } catch (error) {
    yield put(deleteNewsFail(error))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error. Intente nuevamente más tarde.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* newsSaga() {
  yield takeEvery(GET_NEWS, fetchAllNews)
  yield takeEvery(ADD_NEW_NEWS, onAddNewNews)
  yield takeEvery(ADD_IMAGE_NEWS, onAddImageNews)
  yield takeEvery(UPDATE_STATUS_NEWS, onCangeStatusNews)
  yield takeEvery(UPDATE_NEWS, onUpdateNews)
  yield takeEvery(DELETE_NEWS, onDeleteNews)
}

export default newsSaga
