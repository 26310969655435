import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

// Import Routes
import authProtectedRoutes from 'routes/admin.routes'
import publicRoutes from 'routes/public.routes'
import AppRoute from 'routes/private.routes'

// layouts
import VerticalLayout from 'layouts/VerticalLayout'
import HorizontalLayout from 'layouts/HorizontalLayout'
import PublicLayout from './layouts/PublicLayout'

import ScrollToTop from './helpers/ScrollToTop'
import { GlobalStyle } from './components/common/styles/global'
import { loginSuccess } from 'store/actions'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.getLayout = this.getLayout.bind(this)
  }

  /**
   * Returns the layout
   * @returns
   */
  getLayout = () => {
    let layoutCls = HorizontalLayout

    switch (this.props.layout.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout
        break
      case 'vertical':
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  render() {
    const Layout = this.getLayout()
   
    return (
      
        <BrowserRouter>
          <GlobalStyle />
          <ScrollToTop />
          <Switch>
            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={PublicLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}
          </Switch>
        </BrowserRouter>
      
    )
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    currentUser: state.Login,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginSuccess: (currentUser) => dispatch(loginSuccess(currentUser)),
  }
}

App.propTypes = {
  layout: PropTypes.object,
  currentUser: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
