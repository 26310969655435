class TokenService {
  getSessionRefreshToken() {
    const user = JSON.parse(sessionStorage.getItem('authUser'))

    return user?.token
  }

  getSessionToken() {
    const user = JSON.parse(sessionStorage.getItem('authUser'))
    return user?.token
  }

  updateSessionToken(token) {
    let user = JSON.parse(sessionStorage.getItem('authUser'))
    user.token = token
    console.log(user);
    console.log(token);
    sessionStorage.setItem('authUser', JSON.stringify(user))
  }

  getUser() {
    const user = JSON.parse(sessionStorage.getItem('authUser'))
    return user
  }

  setUser(user) {
    sessionStorage.setItem('authUser', JSON.stringify(user))
  }

  setMe(user) {
    sessionStorage.setItem('profile', JSON.stringify(user))
  }

  removeUser() {
    sessionStorage.removeItem('authUser')
    sessionStorage.removeItem('profile')
  }

  isAuthorized() {
    return this.getUser() ? true : false
  }
}

export default new TokenService()
