import React, { Component } from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import { Styles } from './styles/breadcrumb'

export class BreadcrumbBox extends Component {
  state = {
    backgroundImage: 'breadcrumb-bg.jpg',
  }

  render() {
    return (
      <Styles>
        <section
          className="breadcrumb-area"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${this.state.backgroundImage})`,
          }}
        >
          <Container>
            <Row>
              <Col md="12" className="text-center">
                <div className="breadcrumb-box">
                  <h2 className="breadcrumb-title">{this.props.title}</h2>
                  <Breadcrumb>
                    <Breadcrumb.Item href={process.env.PUBLIC_URL + '/'}>
                      Inicio
                    </Breadcrumb.Item>
                    {this.props.category ? (
                      <Breadcrumb.Item
                        href={
                          process.env.PUBLIC_URL +
                          '/' +
                          this.props.category.toLowerCase()
                        }
                      >
                        {this.props.category}
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item active>
                        {this.props.title}
                      </Breadcrumb.Item>
                    )}
                  </Breadcrumb>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    )
  }
}
