import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/stickyMenu";
import Menu from './Menu';
import Logo from './Logo'

function StickyMenu() {
    useEffect(() => {
        window.addEventListener("scroll", () => {
            const stickyMenu = document.querySelector(".sticky-menu");

            if (window.scrollY > 160) {
                stickyMenu.classList.add("sticky");
            } else {
                stickyMenu.classList.remove("sticky");
            }
        });
    });

    return (
        <Styles>
            {/* Sticky Menu */}
            <section className="sticky-menu">
                <Container>
                    <Row>
                        <Col md="3">
                            <Logo />
                        </Col>
                        <Col md="9">
                            <div className="menu-box d-flex justify-content-end">
                                <Menu />
                                
                                <div className="apply-btn">
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScNz5qeK7_x0j2hJlwWpUfv8cPIqod0yfElHrAZxdvSdVkkVQ/viewform" target='blank'><i className="las la-clipboard-list"></i>Ingresantes</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}

export default StickyMenu