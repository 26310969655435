import React, { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import Header from '../components/Header';
import HeroSlider from '../components/HeroSlider';
import IconBox from '../components/IconBox';
import AboutUs from '../components/AboutUs';
import FaqEvent from '../components/FaqEvent';
import HelpArea from '../components/HelpArea';
import HomeBlog from '../components/HomeBlog';
import CampusTour from '../components/CampusTour';
import Footer from '../components/Footer';
import HeroImage from 'components/HeroImage';

export default class HomeOne extends Component {
    render() {
        return (
            <div className="main-wrapper" >

                {/* Header */}
                < Header />

                {/* Hero Slider */}
                {/* < HeroSlider /> */}
                < HeroImage />

                {/* Icon Box */}
                < IconBox />

                {/* About Area */}
                < AboutUs />

                {/* Blog Area */}
                < HomeBlog />

                {/* Faq & Event Area */}
                < FaqEvent />

                {/* Help Area */}
                < HelpArea />

                {/* Campus Tour */}
                < CampusTour />

                {/* Footer */}
                < Footer />

            </div >
        )
    }
}
