import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Styles } from '../styles/courseTag';

class CourseTag extends Component {
    render() {
        return (
            <Styles>
                {/* Course Tag */}
                <div className="course-tag">
                    <h5>Etiquetas</h5>
                    <div className="tag-box">
                        <Link to={process.env.PUBLIC_URL + "/"}>Covnid-19</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Jardín</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Biología</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Ministerio</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Comunidad</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Actos</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Exámenes</Link>
                        <Link to={process.env.PUBLIC_URL + "/"}>Inscripciones</Link>
                    </div>
                </div>
            </Styles>
        )
    }
}

export default CourseTag
