import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import HeaderTwo from 'components/HeaderTwo'
import FooterTwo from 'components/FooterTwo'
import { BreadcrumbBox } from 'components/common/Breadcrumb'

import Pagination from 'components/common/Pagination'
import BlogSidebar from './components/BlogSidebar'
import { Styles } from './styles/blog'
import BlogCard from './components/BlogCard'

// import Datas from 'data/blog/grid.json'
import newsService from 'services/news/news.service'

class BlogGrid extends Component {
  state = {
    noticias: [],
    currentPage: 1,
    currentDataStatus: false,
  }
  PageSize = 6

  componentDidMount() {
    newsService.getPublished()
      .then(res => res.data)
      .then(data => {
        this.setState({ noticias: data.data })
      })
    // this.setState({ noticias: Datas })
    this.setState({ currentDataStatus: this.currentData() })
  }

  componetDidUpdate(prevProps, prevState) {
    if (this.state.currentPage !== prevState.currentPage) {
      this.setState({ currentDataStatus: this.currentData() })
    }
  }

  currentData = () => {
    const firstPageIndex = (this.state.currentPage - 1) * this.PageSize
    const lastPageIndex = firstPageIndex + this.PageSize
    return this.state.noticias.slice(firstPageIndex, lastPageIndex)
  }
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper blog-grid-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcroumb */}
          <BreadcrumbBox title="Noticias" />

          {/* Blog Classic */}
          <section className="blog-grid-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="7">
                  <Row>
                  {this.currentData().map((data, i) => (
                      <BlogCard key={i} noticia={data} />
                    ))}
                  </Row>

                  <div className="text-center">
                    <Pagination
                      className="pagination-bar"
                      currentPage={this.state.currentPage}
                      totalCount={this.state.noticias.length}
                      pageSize={this.PageSize}
                      onPageChange={page =>
                        this.setState({ currentPage: page })
                      }
                    />
                  </div>
                </Col>
                <Col lg="3" md="4" sm="5">
                  <BlogSidebar />
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    )
  }
}

export default BlogGrid
