import React from 'react'
import { Route, Switch } from 'react-router-dom'


import BannersList from './banners-list'
import BannerCreate from './banners-create'

export const Banners = props => {
  const { match } = props
  const { path } = match

console.log(path);
  return (
    <>
      <Switch>
        {/* ARTICLES */}
        <Route path={`${path}/create`}>
          <BannerCreate {...props} />
        </Route>
        <Route path={`${path}/:id/edit`}>
          <BannerCreate {...props} />
        </Route>
        <Route path={`${path}/:id`}>{/* <UsersOverview {...props} /> */}</Route>

        <Route path={path} component={BannersList} {...props} />
      </Switch>
    </>
  )
}

export default Banners
