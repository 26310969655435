import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import HeaderTwo from 'components/HeaderTwo'
import { BreadcrumbBox } from 'components/common/Breadcrumb'
import Pagination from 'components/common/Pagination'
import FooterTwo from 'components/FooterTwo'
import { Styles } from './styles/event'
// import eventsService from 'services/events/events.service'
import EventCard from './components/EventCard'

import Datas from 'data/event/events.json'
class Events extends Component {
  state = {
    eventos: [],
    currentPage: 1,
    currentDataStatus: false,
  }
  PageSize = 5;

  componentDidMount() {
    // eventsService.getPublished()
    //   .then(res => res.data)
    //   .then(data => {
    //     this.setState({ eventos: data.data })
    //   })
    this.setState({eventos: Datas})
    this.setState({currentDataStatus: this.currentData()})
  }

  componetDidUpdate(prevProps, prevState) {
    if(this.state.currentPage !== prevState.currentPage) {
      this.setState({currentDataStatus: this.currentData()})
    }
  }

  currentData = () => {
    const firstPageIndex = (this.state.currentPage - 1) * this.PageSize;
    const lastPageIndex = firstPageIndex + this.PageSize;
    return this.state.eventos.slice(firstPageIndex, lastPageIndex);
  }

  render() {

    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcroumb */}
          <BreadcrumbBox title="Eventos" />

          {/* Events Area */}
          <section className="event-page-area">
            <Container>
              <Row>
                <Col lg="9" md="12">
                  {this.currentData().map((data, i) => (
                    <EventCard event={data} key={i} />
                  ))}

                  <Col md="12" className="text-center">
                  <Pagination
                    className="pagination-bar"
                    currentPage={this.state.currentPage}
                    totalCount={this.state.eventos.length}
                    pageSize={this.PageSize}
                    onPageChange={page => this.setState({currentPage: page})}
                  />
                  </Col>
                </Col>

                {/* <Col lg="3" md="0">
                                    <CourseSidebar />
                                </Col> */}
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    )
  }
}

export default Events
