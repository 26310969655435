import React, { Component } from 'react'
import HeaderTwo from 'components/HeaderTwo'
import { BreadcrumbBox } from 'components/common/Breadcrumb'
import AboutUsTwo from 'components/AboutUsTwo';
// import IconBox from 'components/IconBox';
// import TestimonialSlider from 'components/TestimonialSlider';
import TabBox from 'components/TabBox'
import FaqEvent from 'components/FaqEvent'
import FooterTwo from 'components/Footer'
import { Styles } from './styles/about'

class About extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper about-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcroumb */}
          <BreadcrumbBox title="Nuestros Comienzos" />

          {/* About Area */}
          <AboutUsTwo />

          {/* Tab Section */}
          <TabBox />

          {/* Faq & Event Area */}
          <FaqEvent />

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    )
  }
}

export default About
