import api from 'config/api'
import * as url from './url_helper'

class NewsService {

  getNews (id) {
    return api.get(id)
  }
  
  getNewsSlug (slug) {
    return api.get(url.GET_NEWS_PUBLISHED + '/' + slug)
  }

  getPublished() {
    return api
      .get(url.GET_NEWS_PUBLISHED)
  }

  getLastPublished() {
    return api
      .get(url.GET_NEWS_LAST_PUBLISHED)
  }
  // get newss
  getAllNews () { 
    return api
      .get(url.GET_NEWS)
  }

// add news
  addNewNews ( news ) { 
    return api.post(url.ADD_NEW_NEWS, news)
  }

// update news
  updateNews ( id, news ) { 
    return api.put(url.UPDATE_NEWS + `/${id}`, news)
  }

// delete news
  deleteNews ( newsId ) {
    return api.delete(`${url.DELETE_NEWS}/${newsId}`)
  }
  
  changeStatus ( newsId, news ) {
    return api
      .put(`${url.UPDATE_NEWS}/${newsId}/changestatus`, news)
  }
}

export default new NewsService()