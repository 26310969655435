import React from 'react'

const FooterLogoInfo = props => {
  const { height } = props
  return (
    <div className="footer-logo-info">
      <img
        src={process.env.PUBLIC_URL + '/assets/images/logo_secundaria.webp'}
        alt="escudo institucion"
        height={height ? height : '200px'}
        className="img-fluid"
      />
      <p>Instituto "Nuestra Señora de Guadalupe" Nro 1035 y Nro 8136</p>
      <p>Niveles: Inicial (3 a 5 años), Primaria y Secundaria.</p>
      <ul className="list-unstyled">
        <li>
          <i className="las la-map-marker"></i>9 de julio 2558, CP 3000
        </li>
        <li>
          <i className="las la-envelope"></i>prim1035_santafe@santafe.edu.ar
        </li>
        <li>
          <i className="las la-envelope"></i>colegioguadalupe8136@gmail.com.ar
        </li>
        <li>
          <i className="las la-phone"></i>+54 342 4525489/4559546
        </li>
      </ul>
    </div>
  )
}

export default FooterLogoInfo
