import { takeEvery, fork,put, call,all } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, PROFILE_SUCCESS } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

import AuthService from 'services/auth/auth.service'

function* editProfile({ payload: { user } }) {
  try {
   
      const response = yield call(AuthService.postProfile, user)
      yield put(profileSuccess(response))
    
  } catch (error) {
    yield put(profileError(error))
  }
}

function* getProfile() {
  try {
    const response = yield call(AuthService.getProfile)
    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(PROFILE_SUCCESS, getProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
