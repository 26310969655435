import React, { Component } from 'react'
import Datas from '../data/hero/hero-slider.json'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import Swiper from 'react-id-swiper';
import { Styles } from './styles/heroSlider'

import bannersService from 'services/banners/banners.service'

class HeroSlider extends Component {
  state = {
    banners: [],
  }

  componentDidMount() {
    bannersService
      .getBannersPublished()
      .then(res => res.data)
      .then(data => {
        this.setState({ banners: data.data })
      })
  }

  render() {
    const settings = {
      slidesPerView: 'auto', 
      centeredSlides: true,
      lazy: true,     
      loop: true,
      speed: 1000,
      autoplay: {
          delay: 1000,
          disableOnInteraction: false
      },
      watchSlidesVisibility: true,
      effect: 'fade',
      navigation: {
          nextEl: '.slider-button-next',
          prevEl: '.slider-button-prev'
      },
      renderPrevButton: () => (
          <div className="swiper-btn slider-button-prev">
            <i className="flaticon-arrow-left-th"></i>
          </div>
      ),
      renderNextButton: () => (
          <div className="swiper-btn slider-button-next">
            <i className="flaticon-arrow-right-th"></i>
          </div>
      )
    };

    return (
      <Styles>
        {/* Hero Slider */}
        <section className="hero-slider-area">
          <Swiper {...settings} >
            {Datas.map((data, i) => (
              <div className="slider-item" key={i}>
                <div className="image-container">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/${data.backgroundImage}`
                    }
                    className="slider-image swiper-lazy"
                    alt={data.backgroundImage}
                  />
                </div>
                <div className="slider-table">
                  <div className="slider-tablecell">
                    <Container>
                      <Row>
                        <Col md="12">
                          <div className={data.uniqClass}>
                            <div className="slider-title">
                              <p>{data.title}</p>
                            </div>
                            <div className="slider-desc">
                              <h1>{data.desc}</h1>
                            </div>
                            <div className="slider-btn">
                              {data.btnOneLink ? (
                                <div className="slider-btn">
                                  <Link className="slider-btn1" to={ process.env.PUBLIC_URL}>
                                    Más Información
                                  </Link>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            ))}
          </Swiper>
        </section>
      </Styles>
    )
  }
}

export default HeroSlider
