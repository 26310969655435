import {
  GET_BANNERS,
  GET_BANNERS_FAIL,
  GET_BANNERS_SUCCESS,
  ADD_NEW_BANNER,
  ADD_BANNER_SUCCESS,
  ADD_BANNER_FAIL,
  ADD_IMAGE_BANNER,
  ADD_IMAGE_BANNER_SUCCESS,
  ADD_IMAGE_BANNER_FAIL,
  UPDATE_STATUS_BANNER,
  UPDATE_STATUS_BANNER_SUCCESS,
  UPDATE_STATUS_BANNER_FAIL,
  UPDATE_BANNER,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAIL,
  DELETE_BANNER,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
} from './actionTypes'

// list
export const getBanners = () => ({
  type: GET_BANNERS,
})

export const getBannersSuccess = banners => ({
  type: GET_BANNERS_SUCCESS,
  payload: banners,
})

export const getBannersFail = error => ({
  type: GET_BANNERS_FAIL,
  payload: error,
})
// end list \\

// add
export const addNewBanner = (banner, history) => ({
  type: ADD_NEW_BANNER,
  payload: {
    banner,
    history,
  },
})

export const addBannerSuccess = banner => ({
  type: ADD_BANNER_SUCCESS,
  payload: banner,
})

export const addBannerFail = error => ({
  type: ADD_BANNER_FAIL,
  payload: error,
})
// end add \\

// add IMAGE
export const addImageBanner = (image, history) => ({
  type: ADD_IMAGE_BANNER,
  payload: {
    image,
    history,
  },
})

export const addImageBannerSuccess = image => ({
  type: ADD_IMAGE_BANNER_SUCCESS,
  payload: image,
})

export const addImageBannerFail = error => ({
  type: ADD_IMAGE_BANNER_FAIL,
  payload: error,
})
// end add IMAGE\\

// UPDATE STATUS
export const changeStatusBanner = (banner, history, id) => ({
  type: UPDATE_STATUS_BANNER,
  payload: {
    banner,
    history,
    id,
  },
})

export const changeStatusBannerSuccess = banner => ({
  type: UPDATE_STATUS_BANNER_SUCCESS,
  payload: banner,
})

export const changeStatusBannerFail = error => ({
  type: UPDATE_STATUS_BANNER_FAIL,
  payload: error,
})
// end UPDATE STATUS\\

// edit
export const updateBanner = (banner, history, id) => ({
  type: UPDATE_BANNER,
  payload: {
    banner,
    history,
    id,
  },
})

export const updateBannerSuccess = banner => ({
  type: UPDATE_BANNER_SUCCESS,
  payload: banner,
})

export const updateBannerFail = error => ({
  type: UPDATE_BANNER_FAIL,
  payload: error,
})
// end edit \\

// delete
export const deleteBanner = (bannerId, history) => ({
  type: DELETE_BANNER,
  payload: {
    bannerId,
    history,
  },
})

export const deleteBannerSuccess = bannerId => ({
  type: DELETE_BANNER_SUCCESS,
  payload: bannerId,
})

export const deleteBannerFail = error => ({
  type: DELETE_BANNER_FAIL,
  payload: error,
})
// end delete \\
