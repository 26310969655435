import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Styles } from './styles/contact';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class GoogleMap extends Component {

    static defaultProps = {
        center: {
            lat: -31.645698,
            lng: -60.7110897
        },
        zoom: 15
    };

    render() {
        return (
            <Styles>
                {/* Google Map */}
                <div className="google-map-area">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_APIKEY }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                    >
                        <AnyReactComponent
                            lat={-31.645698}
                            lng={-60.7110897}
                            text="Colegio Nuestra Señora de Guadalupe"
                        />
                    </GoogleMapReact>
                </div>
            </Styles>
        )
    }
}

export default GoogleMap