import React from 'react'
import { Badge } from 'reactstrap'

const BadgeStatus = ({color, title}) => {
  return (
      <Badge color={color} className={`bg-${color}`} pill> {title}</Badge>
  )
}

export default BadgeStatus
