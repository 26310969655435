import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"

// Crypto Redux States
import {
  GET_BANNERS,
  ADD_NEW_BANNER,
  ADD_IMAGE_BANNER,
  UPDATE_STATUS_BANNER,
  DELETE_BANNER,
  UPDATE_BANNER
} from "./actionTypes"

import {
  getBannersSuccess,
  getBannersFail,
  addBannerFail,
  addBannerSuccess,
  addImageBannerFail,
  addImageBannerSuccess,
  changeStatusBannerFail,
  changeStatusBannerSuccess,
  updateBannerSuccess,
  updateBannerFail,
  deleteBannerSuccess,
  deleteBannerFail,
} from "./banner.actions"

//Include Both Helper File with needed methods
import BannersService from "services/banners/banners.service"

function* fetchBanners() {
  try {
    const response = yield call(BannersService.getBanners)
    if (response.status === 200) {
      yield put(getBannersSuccess(response.data.data))
      
    }
  } catch (error) {
    yield put(getBannersFail(error.response))
  }
}

function* onAddNewBanner({ payload: { banner, history } }) {
  try {
    const response = yield call(BannersService.addNewBanner, banner)
    yield put(addBannerSuccess(response.data))
    history.push('/banner')
  } catch (error) {
    yield put(addBannerFail(error))
  }
}

function* onAddImageBanner({ payload: { image, history } }) {
  console.log(image);
  try {
    const response = yield call(BannersService.addImageBanner, image)
    yield put(addImageBannerSuccess(response.data))
    history.push('/banners')
  } catch (error) {
    yield put(addImageBannerFail(error))
  }
}

function* onCangeStatusBanner({ payload: { banner, history, id} }) {
  try {
    const response = yield call(BannersService.changeStatus, id, banner)
    
      yield put(changeStatusBannerSuccess(response.data))
      toast.success('Banner actualizado satisfactoriamente.')
    
    history.push('/banners')
  } catch (error) {
    yield put(changeStatusBannerFail(error))
  }
}

function* onUpdateBanner({ payload: { banner, history, id } }) {
  try {
    const response = yield call(BannersService.updateBanner,id, banner)
    yield put(updateBannerSuccess(response.data))
    history.push('/banners')
  } catch (error) {
    yield put(updateBannerFail(error))
  }
}

function* onDeleteBanner({ payload: { bannerId, history} }) {
  try {
    const response = yield call(BannersService.deleteBanner, bannerId)
    yield put(deleteBannerSuccess(response.data))
    history.push('/banners')
  } catch (error) {
    yield put(deleteBannerFail(error))
  }
}

function* bannerSaga() {
  yield takeEvery(GET_BANNERS, fetchBanners)
  yield takeEvery(ADD_NEW_BANNER, onAddNewBanner)
  yield takeEvery(ADD_IMAGE_BANNER, onAddImageBanner)
  yield takeEvery(UPDATE_STATUS_BANNER, onCangeStatusBanner)
  yield takeEvery(UPDATE_BANNER, onUpdateBanner)
  yield takeEvery(DELETE_BANNER, onDeleteBanner)
}

export default bannerSaga
