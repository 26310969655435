import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import { Styles } from "./styles/campusTour";

class CampusTour extends Component {
    state = {
        secTitle: "Nuestra comunidad en imágenes.",
    }

    render() {
        return (
            <Styles>
                {/* Campus Tour */}
                <section className="campus-tour">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>{this.state.secTitle}</h4>
                                </div>
                            </Col>
                            <Col lg="2" md="3">
                                <div className="tour-box">
                                    <ModalImage small={process.env.PUBLIC_URL + "/assets/images/gallery/fachada-guadalupe.webp"} large={process.env.PUBLIC_URL + "/assets/images/gallery/fachada-guadalupe.webp"} alt="galeria" />
                                </div>
                                <div className="tour-box">
                                    <ModalImage small={process.env.PUBLIC_URL + "/assets/images/gallery/Gimnasio.webp"} large={process.env.PUBLIC_URL + "/assets/images/gallery/Gimnasio.webp"} alt="galeria" />
                                </div>
                            </Col>
                            <Col lg="4" md="6">
                                <Row>
                                    <Col lg="6" md="6">
                                        <div className="tour-box">
                                            <ModalImage small={process.env.PUBLIC_URL + "/assets/images/gallery/Aula-Talleres.webp"} large={process.env.PUBLIC_URL + "/assets/images/gallery/Aula-Talleres.webp"} alt="galeria" />
                                        </div>
                                    </Col>
                                    <Col lg="6" md="6">
                                        <div className="tour-box">
                                            <ModalImage small={process.env.PUBLIC_URL + "/assets/images/gallery/Laboratorio.webp"} large={process.env.PUBLIC_URL + "/assets/images/gallery/Laboratorio.webp"} alt="galeria" />
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className="tour-box">
                                            <ModalImage small={process.env.PUBLIC_URL + "/assets/images/gallery/plaza-nivel-inicial.webp"} large={process.env.PUBLIC_URL + "/assets/images/gallery/Hall-Ingreso.webp"} alt="galeria" />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="2" md="3">
                                <div className="tour-box">
                                    <ModalImage small={process.env.PUBLIC_URL + "/assets/images/gallery/Gruta-Virgen.webp"} large={process.env.PUBLIC_URL + "/assets/images/gallery/Ingreso.webp"} alt="galeria" />
                                </div>
                            </Col>
                            <Col lg="4" md="12">
                                <Row>
                                    <Col lg="12" md="6">
                                        <div className="tour-box">
                                            <ModalImage small={process.env.PUBLIC_URL + "/assets/images/gallery/Patio-Blanco.webp"} large={process.env.PUBLIC_URL + "/assets/images/gallery/Patio-Blanco.webp"} alt="galeria" />
                                        </div>
                                    </Col>
                                    <Col lg="6" md="3">
                                        <div className="tour-box">
                                            
                                        </div>
                                    </Col>
                                    <Col lg="6" md="3">
                                        <div className="tour-box">
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default CampusTour
