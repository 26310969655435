import api from 'config/api'
import * as url from './url_helper'

class EventsService {

  getEvent (id) {
    return api.get(id)
  }
  
  getEventSlug (slug) {
    return api.get(url.GET_EVENTS_PUBLISHED + '/' + slug)
  }

  getPublished() {
    return api
      .get(url.GET_EVENTS_PUBLISHED)
  }

  // get events
  getEvents () { 
    return api
      .get(url.GET_EVENTS)
  }

// add event
  addNewEvent ( event ) { 
    return api.post(url.ADD_NEW_EVENT, event)
  }

// update event
  updateEvent ( id, event ) { 
    return api.put(url.UPDATE_EVENT + `/${id}`, event)
  }

// delete event
  deleteEvent ( eventId ) {
    return api.delete(`${url.DELETE_EVENT}/${eventId}`)
  }

  changeStatus ( eventId, event ) {
    return api
      .put(`${url.UPDATE_EVENT}/${eventId}/changestatus`, event)
  }
}

export default new EventsService()