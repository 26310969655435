import React, { Component } from 'react'
// import Datas from '../../data/event/details.json'
// import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import Timer from 'react-compound-timer'
import HeaderTwo from 'components/HeaderTwo'
import { BreadcrumbBox } from 'components/common/Breadcrumb'
// import PopularCourse from './../courses/components/PopularCourse'
// import CourseTag from './../courses/components/CourseTag'
import FooterTwo from 'components/FooterTwo'
import { Styles } from './styles/eventDetails'
import eventsService from 'services/events/events.service'
import EventPrevNext from './components/EventsPrevNext'
class EventDetails extends Component {
  state = {
    event: [],
    category: [],
    tags: [],
    nextEvent: [],
    prevEvent: [],
  }

  componentDidMount() {
    eventsService
      .getEventSlug(this.props.match.params.slug)
      .then(res => res.data)
      .then(data => {
        this.setState({
          event: data.data,
          category: data.data.category,
          tags: data.data.tags,
          nextEvent: data.data.next,
          prevEvent: data.data.prev,
        })
      })
  }

  render() {
    const event = this.state.event
    const date = new Date(event.fecha)
    const unixTimeStamp = Math.floor(date.getTime() / 1000)

    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper event-details-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcroumb */}
          <BreadcrumbBox title={event.title} category="Eventos"/>

          {/* Event Details Area */}
          <section className="event-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="12">
                  <div className="event-details-content">
                    <div className="heading">
                      <h4>
                        {event.title}
                      </h4>
                    </div>
                    <div className="event-icon">
                      <ul className="list-unstyled list-inline">
                        <li className="list-inline-item">
                          <i className="las la-calendar"></i> {event.fecha}
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-clock"></i> {event.time}
                        </li>
                        <li className="list-inline-item">
                          <i className="las la-map-marker"></i> {event.location}
                        </li>
                      </ul>
                    </div>
                    <div className="event-details-banner">
                      <img
                        src={event.image_url}
                        alt={event.title}
                        className="img-fluid"
                      />
                    </div>
                    <div className="event-details-overview">
                      <h5>Detalle del Evento</h5>
                      {ReactHtmlParser(event.descripcion)}

                      <p>
                        Más información: <a href={event.link}>AQUÍ</a>
                      </p>
                    </div>
                    

                    <EventPrevNext prev={this.state.prevEvent} next={this.state.nextEvent} />
                  </div>
                </Col>

                <Col lg="3" md="4" sm="12">
                  <div className="event-details-sidebar">
                    <Row>
                      <Col md="12">
                        <div className="event-sidebar-info">
                          <div className="event-sidebar-timer text-center">
                            <Timer
                              initialTime={unixTimeStamp}
                              direction="backward"
                            >
                              <p>
                                <Timer.Days />
                                <span>Días</span>
                              </p>
                              <p>
                                <Timer.Hours />
                                <span>Horas</span>
                              </p>
                              <p>
                                <Timer.Minutes />
                                <span>Minutos</span>
                              </p>
                            </Timer>
                          </div>
                          <ul className="list-unstyled event-info-list">
                          <li>
                              Inicio: <span>{event.fecha}</span>
                            </li>
                            <li>
                              Time: <span>{event.time}</span>
                            </li>
                            <li>
                              Lugar: <span>{event.location}</span>
                            </li>
                            <li>
                              Organizador: <span>{event.organizer}</span>
                            </li>
                            <li>
                              Celular: <span>{event.phone}</span>
                            </li>
                            <li>
                              Email: <span>{event.email}</span>
                            </li>
                            <li>
                              Website: <span>{event.website}</span>
                            </li>
                          </ul>
                          {/* <button type="button" className="buy-btn">
                            Buy Ticket
                          </button> */}
                        </div>
                      </Col>
                      <Col md="12">
                        {/* <PopularCourse /> */}
                      </Col>
                      <Col md="12">
                        {/* <CourseTag /> */}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    )
  }
}

export default EventDetails
