import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Alerts from "./alerts/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
// languages
// import Languages from './translation/reducer';

import Users from "./users/reducer"
import Banners from "./banners/banner.reducer"

// PROFESIONALES
import Category from "./categories/category.reducer"
import News from "./news/news.reducer"
import Courses from './courses/course.reducer'
import Events from './events/event.reducer'

const rootReducer = combineReducers({
  // public
  Alerts,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  // Languages,
  Users,
  Banners,
  Category,
  News,
  Courses,
  Events,
})

export default rootReducer