import React from 'react'
import { Link } from 'react-router-dom'

const Menu = () => {
  return (
    <ul className="nav menu-nav">
      <li className="nav-item active">
        <Link className="nav-link " to={process.env.PUBLIC_URL + '/'}>
          Inicio{' '}
        </Link>
      </li>
      <li className="nav-item dropdown">
        <Link
          className="nav-link dropdown-toggle"
          to={process.env.PUBLIC_URL + '/'}
          data-toggle="dropdown"
        >
          Institucional <i className="las la-angle-down"></i>
        </Link>
        <ul className="dropdown list-unstyled">
          <li className="nav-item">
            <Link className="nav-link" to={process.env.PUBLIC_URL + '/about'}>
              Nuestros comienzos
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={process.env.PUBLIC_URL + '/gallery'}>
              Galeria
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={process.env.PUBLIC_URL + '/contact'}>
              Contacto
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={process.env.PUBLIC_URL + '/faq'}>
              Faq
            </Link>
          </li>
        </ul>
      </li>
      <li className="nav-item dropdown">
        <Link
          className="nav-link dropdown-toggle"
          to={process.env.PUBLIC_URL + '/'}
          data-toggle="dropdown"
        >
          Niveles <i className="las la-angle-down"></i>
        </Link>
        <ul className="dropdown list-unstyled">
        <li className="nav-item">
            <Link
              className="nav-link"
              to={process.env.PUBLIC_URL + '/niveles/inicial'}
            >
              Inicial
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to={process.env.PUBLIC_URL + '/niveles/primario'}
            >
              Primario
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to={process.env.PUBLIC_URL + '/niveles/secundario'}
            >
              Secundario
            </Link>
          </li>
        </ul>
      </li>
      <li className="nav-item ">
        <Link className="nav-link" to={process.env.PUBLIC_URL + '/eventos'}>
          <i className="las la-calendar"></i>Eventos
        </Link>
      </li>
      <li className="nav-item ">
        <Link className="nav-link" to={process.env.PUBLIC_URL + '/noticias'}>
          <i className="las la-newspaper"></i> Noticias{' '}
        </Link>
      </li>
      <li className="nav-item ">
        <a className="nav-link" href={'https://sisweb.nsg.fic.org.ar'} target="blank">
          <i className="las la-coins"></i> ARANCELES{' '}
        </a>
      </li>
    </ul>
  )
}

export default Menu
