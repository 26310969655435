import {
  GET_NEWS,
  GET_NEWS_FAIL,
  GET_NEWS_SUCCESS,
  ADD_NEW_NEWS,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_FAIL,
  ADD_IMAGE_NEWS,
  ADD_IMAGE_NEWS_SUCCESS,
  ADD_IMAGE_NEWS_FAIL,
  UPDATE_STATUS_NEWS,
  UPDATE_STATUS_NEWS_SUCCESS,
  UPDATE_STATUS_NEWS_FAIL,
  UPDATE_NEWS,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAIL,
  DELETE_NEWS,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
} from './actionTypes'

// list
export const getAllNews = () => ({
  type: GET_NEWS,
})

export const getAllNewsSuccess = newss => ({
  type: GET_NEWS_SUCCESS,
  payload: newss,
})

export const getAllNewsFail = error => ({
  type: GET_NEWS_FAIL,
  payload: error,
})
// end list \\

// add
export const addNewNews = (news, history) => ({
  type: ADD_NEW_NEWS,
  payload: {
    news,
    history,
  },
})

export const addNewsSuccess = news => ({
  type: ADD_NEWS_SUCCESS,
  payload: news,
})

export const addNewsFail = error => ({
  type: ADD_NEWS_FAIL,
  payload: error,
})
// end add \\

// add IMAGE
export const addImageNews = (image, history) => ({
  type: ADD_IMAGE_NEWS,
  payload: {
    image,
    history,
  },
})

export const addImageNewsSuccess = image => ({
  type: ADD_IMAGE_NEWS_SUCCESS,
  payload: image,
})

export const addImageNewsFail = error => ({
  type: ADD_IMAGE_NEWS_FAIL,
  payload: error,
})
// end add IMAGE\\

// UPDATE STATUS
export const changeStatusNews = (news, history, id) => ({
  type: UPDATE_STATUS_NEWS,
  payload: {
    news,
    history,
    id,
  },
})

export const changeStatusNewsSuccess = news => ({
  type: UPDATE_STATUS_NEWS_SUCCESS,
  payload: news,
})

export const changeStatusNewsFail = error => ({
  type: UPDATE_STATUS_NEWS_FAIL,
  payload: error,
})
// end UPDATE STATUS\\

// edit
export const updateNews = (news, history, id) => ({
  type: UPDATE_NEWS,
  payload: {
    news,
    history,
    id,
  },
})

export const updateNewsSuccess = news => ({
  type: UPDATE_NEWS_SUCCESS,
  payload: news,
})

export const updateNewsFail = error => ({
  type: UPDATE_NEWS_FAIL,
  payload: error,
})
// end edit \\

// delete
export const deleteNews = (newsId, history) => ({
  type: DELETE_NEWS,
  payload: {
    newsId,
    history,
  },
})

export const deleteNewsSuccess = newsId => ({
  type: DELETE_NEWS_SUCCESS,
  payload: newsId,
})

export const deleteNewsFail = error => ({
  type: DELETE_NEWS_FAIL,
  payload: error,
})
// end delete \\
