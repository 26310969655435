import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import HeaderTwo from '../../components/HeaderTwo'
import { BreadcrumbBox } from '../../components/common/Breadcrumb'
import BlogSidebar from './components/BlogSidebar'
// import CommentForm from './components/CommentForm'
import FooterTwo from '../../components/FooterTwo'
import { Styles } from './styles/blogDetails'
import Moment from 'react-moment'

import NewsService from 'services/news/news.service'
class BlogDetails extends Component {
  state = {
    post: [],
    category: [],
    tags: [],
    author: [],
  }

  componentDidMount() {
    NewsService.getNewsSlug(this.props.match.params.slug)
      .then(res => res.data)
      .then(data => {
        this.setState({
          post: data.data,
          category: data.data.category,
          tags: data.data.tags,
          author: data.data.author,
        })
      })
  }

  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper blog-details-page">
          {/* Header 2 */}
          <HeaderTwo />

          {/* Breadcroumb */}
          <BreadcrumbBox title={this.state.post.title} category={'noticias'}/>

          {/* Blog Details */}
          <section className="blog-details-area">
            <Container>
              <Row>
                <Col lg="9" md="8" sm="7">
                  <div className="blog-details-box">
                    <div className="blog-details-banner">
                      <img
                        src={this.state.post.image_url}
                        alt={this.state.post.title}
                        className="img-fluid"
                      />
                    </div>
                    <div className="heading">
                      <h4>
                      {this.state.post.title}
                      </h4>
                    </div>
                    <div className="blog-auth_date d-flex">
                      <div className="author-img d-flex">
                        
                        <p>
                          <Link to={process.env.PUBLIC_URL + '/'}>
                          {this.state.author_name}
                          </Link>
                        </p>
                      </div>
                      <div className="post-date">
                        <p>
                          <i className="las la-calendar"></i> 
                          <Moment format="DD/MM/YYYY">{this.state.post.created_at}</Moment>
                        </p>
                      </div>
                      <div className="post-category">
                        <p>
                          <Link
                            to={
                              process.env.PUBLIC_URL +
                              '/category/' +
                              this.state.category.slug
                            }
                          >
                            <i className="las la-bookmark"></i>
                            {this.state.category.title}
                          </Link>
                        </p>
                      </div>
                      
                    </div>
                    <div className="blog-details-desc">
                      {ReactHtmlParser(this.state.post.content)}
                    </div>
                    <div className="blog-tag_share d-flex justify-content-between">
                      <div className="blog-tag">
                        <ul className="tags list-unstyled list-inline">
                          <li className="list-inline-item">Tags:</li>
                          {this.state.tags.map((data, i) => (
                            <li key={i} className="list-inline-item">
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  '/noticias/tags/' +
                                  data.slug
                                }
                              >
                                {data.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {/* <div className="blog-share">
                        <ul className="social list-unstyled list-inline">
                          <li className="list-inline-item">Share:</li>
                          <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + '/'}>
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + '/'}>
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + '/'}>
                              <i className="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + '/'}>
                              <i className="fab fa-youtube"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + '/'}>
                              <i className="fab fa-dribbble"></i>
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                    {/* <div className="blog-comments">
                      <h5>Comments (03)</h5>
                      <div className="comment-box d-flex">
                        <div className="comment-image">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/testimonial-2.jpg`
                            }
                            alt=""
                          />
                        </div>
                        <div className="comment-content">
                          <div className="content-title d-flex justify-content-between">
                            <div className="comment-writer">
                              <h6>Mark Shadow</h6>
                              <p>Mar 26, 2020 | 06:30pm</p>
                            </div>
                            <div className="reply-btn">
                              <button type="button">
                                <i className="las la-reply-all"></i> Reply
                              </button>
                            </div>
                          </div>
                          <div className="comment-desc">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Architecto laborum quas placeat perspiciatis
                              est, nisi expedita consectetur sit minus illum
                              laudantium nostrum dolore odit asperiores quisquam
                              ad enim iusto laborum quas placeat perspiciatis
                              saepe.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="comment-box d-flex">
                        <div className="comment-image">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/testimonial-1.jpg`
                            }
                            alt=""
                          />
                        </div>
                        <div className="comment-content">
                          <div className="content-title d-flex justify-content-between">
                            <div className="comment-writer">
                              <h6>Katrin Kay</h6>
                              <p>Mar 26, 2020 | 06:30pm</p>
                            </div>
                            <div className="reply-btn">
                              <button type="button">
                                <i className="las la-reply-all"></i> Reply
                              </button>
                            </div>
                          </div>
                          <div className="comment-desc">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Architecto laborum quas placeat perspiciatis
                              est, nisi expedita consectetur sit minus illum
                              laudantium nostrum dolore odit asperiores quisquam
                              ad enim iusto laborum quas placeat perspiciatis
                              saepe.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="comment-box d-flex">
                        <div className="comment-image">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/testimonial-2.jpg`
                            }
                            alt=""
                          />
                        </div>
                        <div className="comment-content">
                          <div className="content-title d-flex justify-content-between">
                            <div className="comment-writer">
                              <h6>David Show</h6>
                              <p>Mar 26, 2020 | 06:30pm</p>
                            </div>
                            <div className="reply-btn">
                              <button type="button">
                                <i className="las la-reply-all"></i> Reply
                              </button>
                            </div>
                          </div>
                          <div className="comment-desc">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Architecto laborum quas placeat perspiciatis
                              est, nisi expedita consectetur sit minus illum
                              laudantium nostrum dolore odit asperiores quisquam
                              ad enim iusto laborum quas placeat perspiciatis
                              saepe.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <CommentForm /> */}
                  </div>
                </Col>
                <Col lg="3" md="4" sm="5">
                  <BlogSidebar />
                </Col>
              </Row>
            </Container>
          </section>

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    )
  }
}

export default BlogDetails
