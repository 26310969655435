import api from 'config/api'
import * as url from './url_helper'

class CoursesService {

  getCourse (id) {
    return api.get(id)
  }

  getCourseSlug (slug) {
    return api.get(url.GET_COURSES_PUBLISHED + '/' + slug)
  }

  getPublished() {
    return api
      .get(url.GET_COURSES_PUBLISHED)
  }

  // get courses
  getCourses () { 
    return api
      .get(url.GET_COURSES)
  }

// add course
  addNewCourse ( course ) { 
    return api.post(url.ADD_NEW_COURSE, course)
  }

// update course
  updateCourse ( id, course ) { 
    return api.put(url.UPDATE_COURSE + `/${id}`, course)
  }

// delete course
  deleteCourse ( courseId ) {
    return api.delete(`${url.DELETE_COURSE}/${courseId}`)
  }

  changeStatus ( courseId, course ) {
    return api
      .put(`${url.UPDATE_COURSE}/${courseId}/changestatus`, course)
  }
}

export default new CoursesService()