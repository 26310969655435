import api from 'config/api'
import * as url from './url_helper'

class BannersService {

  getBanner (id) {
    return api.get(id)
  }

  // get banners
  getBanners () { 
    return api
      .get(url.GET_BANNERS)
  }

  getBannersPublished () { 
    return api
      .get(url.GET_BANNERS_PUBLIC)
  }

// add banner
  addNewBanner ( banner ) {
    return api
      .post(url.ADD_NEW_BANNER, banner)
  }

  addImageBanner ( file ) {
    return api
      .post(url.ADD_IMAGE_BANNER, file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
    })
  }

// update banner
  updateBanner ( id, banner ) { 
    return api
      .put(url.UPDATE_BANNER + `/${id}`, banner)
  }

// delete banner
  deleteBanner ( bannerId ) {
    return api
      .delete(`${url.DELETE_BANNER}/${bannerId}`)
  }

  changeStatus ( bannerId, banner ) {
    return api
      .put(`${url.UPDATE_BANNER}/${bannerId}/changestatus`, banner)
  }
}

export default new BannersService()