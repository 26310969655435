import React, { useState, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import { Alert, Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Label, Row, Spinner } from 'reactstrap'
import Breadcrumbs from 'layouts/Common/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useParams } from 'react-router'
import { updateBanner, addNewBanner } from 'store/actions'
import FileUpload from 'pages/admin/components/fileUpload/FileUpload'
import CloudService from 'services/cloud.service'

const initialBannerState = {
  'title': '',
  'slug': '',
  'subtitle': '',
  'link': '',
  'image_url': '',
  'status': '',
  'sorted': '',
  'file': [],
  'img_data': []
}

const BannerCreate = (props) => {
  const {error, loading } = props
  const { id } = useParams()
  const [bannerForm, setBannerForm] = useState(initialBannerState)
  const [editMode, setEditMode] = useState(false)
  const banners = useSelector(state => state.Banners.banners)
  const { title, slug, subtitle, link, image_url, status, sorted } = bannerForm

  const updateUploadedFiles = (files) => {
    
    CloudService.imageUp(files[0],'website/banners')
      .then( res => res.json())
      .then( data => {
        const newBannerform = {
          ...bannerForm,
          image_url: data.secure_url,
          img_data: data,
        }
        setBannerForm(newBannerform)
        
      })
  }

  const dispatch = useDispatch()
  
  useEffect(() => {
    if(id) {
      const singleBanner = banners.find(banner => banner.id === Number(id))
      setBannerForm({...singleBanner})
      setEditMode(true)
    } else {
      setEditMode(false)
      setBannerForm({...initialBannerState})
    }
    return () => {
      setBannerForm({...initialBannerState})
    }
  }, [id,banners])

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    const allValues = { ...bannerForm, ...values}
    
    if (editMode) {
      dispatch(updateBanner(allValues, props.history, id))
      setEditMode(false)
    } else {
      dispatch(addNewBanner(allValues, props.history))
    }
    props.history.goBack()
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>{id ? "Editar" : "Nuevo"} Banner | Colegio NS Guadalupe - Santa Fe</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Utility" breadcrumbItem={id ? 'Editar Banner' : 'Nuevo Banner'} />
        <Row>
          <Col lg="12">
            <Card>
              <CardTitle className='mb-4'>
                {id ? "Editar Banner: " + slug : "Crear nuevo Banner" }
              </CardTitle>
              <CardBody className='mb-4'>
                <AvForm onValidSubmit={handleValidSubmit} >
                  {error ? <Alert color="danger">{error}</Alert> : null}

                {id ? 
                  <FormGroup className="mb-4" row>
                    <Col lg="10">
                      <AvField
                        id="id"
                        name="id"
                        type="hidden"
                        className="form-control"
                        value={id || ''}
                      />
                    </Col>
                  </FormGroup>
                : null}
                <FormGroup className="mb-4" row>
                    {image_url ? 
                      <>
                        <img  alt={title} src={image_url} />
                        {/* <AvField
                          id="image_url"
                          name="image_url"
                          type="text"
                          className="form-control"
                          value={image_url || ''}
                        /> */}
                     </>
                    : null}
                    <FileUpload
                      accept=".jpg,.png,.jpeg"
                      label="Imagen del Banner"
                      updateFilesCb={updateUploadedFiles}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4" >
                    <Label htmlFor="title" className="col-form-label col-lg-2">
                      Título
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="title"
                        name="title"
                        type="text"
                        className="form-control"
                        value={title || ''}
                        placeholder="Ingresar un nombre"
                        autoFocus={true}
                        required
                        errorMessage="Debe ingresar un nombre válido..."
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="mb-4" >
                    <Label htmlFor="subtitle" className="col-form-label col-lg-2">
                      Sub-Título
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="subtitle"
                        name="subtitle"
                        type="text"
                        className="form-control"
                        value={subtitle || ''}
                        placeholder="Ingresar un subtitulo"
                        required
                        errorMessage="Debe ingresar un subtitulo válido..."
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" >
                    <Label htmlFor="link" className="col-form-label col-lg-2">
                      Link
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="link"
                        name="link"
                        type="text"
                        className="form-control"
                        value={link || ''}
                        placeholder="Ingresar un link"
                      />
                    </Col>
                  </FormGroup>
                  
                <Row>
                <Col lg="5">
                  <FormGroup className="mb-3 mt-3 mt-lg-0 select2-container" >
                    <Label
                      htmlFor="status"
                      className="col-form-label col-lg-3"
                    >
                      Estado
                    </Label>
                      <AvField
                          id="status"
                          name="status"
                          type="select"
                          className="form-select"
                          value={status || 'DRAFT'}
                          required
                          errorMessage="Debe ingresar un estado válido..."
                        >
                          <option key='1' value="DRAFT">BORRADOR</option>
                          <option key='2' value="PUBLISHED">PUBLICADO</option>
                          <option key='3' value="UNPUBLISHED">ARCHIVO</option>
                        </AvField>
                      
                  </FormGroup>

                </Col>
                <Col lg="5">
                  <FormGroup className="mb-4" >
                    <Label
                      htmlFor="author_id"
                      className="col-form-label col-lg-3"
                    >
                      Orden
                    </Label>
                    
                    <AvField
                        id="sorted"
                        name="sorted"
                        type="number"
                        className="form-control"
                        value={sorted || ''}
                        placeholder="Ingresar un número de orden"
                        autoFocus={true}
                        required
                        errorMessage="Debe ingresar un número válido..."
                      />
                    
                  </FormGroup>
                  </Col>
                </Row>
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button type="submit" color="primary" disabled={loading}>
                        {!editMode ? 'Guardar' : 'Actualizar'}
                        {loading && <Spinner color="info" size="sm" />}
                      </Button>{' '}
                      <Button
                        onClick={e => props.history.goBack()}
                        color="danger"
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BannerCreate
