import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"

// Crypto Redux States
import {
  GET_COURSES,
  ADD_NEW_COURSE,
  ADD_IMAGE_COURSE,
  DELETE_COURSE,
  UPDATE_COURSE,
  UPDATE_STATUS_COURSE,
} from "./actionTypes"

import {
  getCoursesSuccess,
  getCoursesFail,
  addCourseFail,
  addCourseSuccess,
  addImageCourseFail,
  addImageCourseSuccess,
  changeStatusCourseFail,
  changeStatusCourseSuccess,
  updateCourseSuccess,
  updateCourseFail,
  deleteCourseSuccess,
  deleteCourseFail,
} from "./course.actions"

//Include Both Helper File with needed methods
import CoursesService from "services/courses/courses.service"
import Swal from "sweetalert2"

function* fetchCourses() {
  try {
    const response = yield call(CoursesService.getCourses)
    if (response.status === 200) {
      yield put(getCoursesSuccess(response.data.data))
      
    }
  } catch (error) {
    yield put(getCoursesFail(error.response))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error. Intente nuevamente más tarde.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* onAddNewCourse({ payload: { course, history } }) {
  try {
    const response = yield call(CoursesService.addNewCourse, course)
    if (response.status === 201) {

      yield put(addCourseSuccess(response.data.data))
      toast.success('Curso agregado satisfactoriamente.')
    }
  } catch (error) {
    yield put(addCourseFail(error))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error. Intente nuevamente más tarde.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* onAddImageCourse({ payload: { image, history } }) {
  
  try {
    const response = yield call(CoursesService.addImageCourse, image)
    yield put(addImageCourseSuccess(response.data.data))
    history.push('/courses')
  } catch (error) {
    yield put(addImageCourseFail(error))
  }
}

function* onCangeStatusCourse({ payload: { course, history, id} }) {
  try {
    const response = yield call(CoursesService.changeStatus, id, course)
    console.log(response);
    if (response.status === 201) {
      
      yield put(changeStatusCourseSuccess(response.data.data))
      toast.success('Curso actualizado satisfactoriamente.' + response.data.message)
    }
  } catch (error) {
    yield put(changeStatusCourseFail(error.response))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* onUpdateCourse({ payload: { course, history, id } }) {
  try {
    const response = yield call(CoursesService.updateCourse,id, course)
    if (response.status === 201) {
      
      yield put(updateCourseSuccess(response.data.data))
      toast.success(`El Curso ${response.data.slug} fue actualizado satisfactoriamente.`)
    }
    
  } catch (error) {
    yield put(updateCourseFail(error))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error. Intente nuevamente más tarde.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* onDeleteCourse({ payload: { courseId, history} }) {
  try {
    const response = yield call(CoursesService.deleteCourse, courseId)
    if (response.status === 200) {
      yield put(deleteCourseSuccess(response.data.data))
      Swal.fire({
        title: 'Eliminado!',
        text: 'Se ha eliminado el curso exitosamente.',
        icon: 'success',
        confirmButtonColor: '#62a086'
      })
    }
  } catch (error) {
    yield put(deleteCourseFail(error.response))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* courseSaga() {
  yield takeEvery(GET_COURSES, fetchCourses)
  yield takeEvery(ADD_NEW_COURSE, onAddNewCourse)
  yield takeEvery(ADD_IMAGE_COURSE, onAddImageCourse)
  yield takeEvery(UPDATE_STATUS_COURSE, onCangeStatusCourse)
  yield takeEvery(UPDATE_COURSE, onUpdateCourse)
  yield takeEvery(DELETE_COURSE, onDeleteCourse)
}

export default courseSaga
