import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify"

// Crypto Redux States
import {
  GET_CATEGORIES,
  ADD_NEW_CATEGORY,
  ADD_IMAGE_CATEGORY,
  UPDATE_STATUS_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY
} from "./actionTypes"

import {
  getCategoriesSuccess,
  getCategoriesFail,
  addCategoryFail,
  addCategorySuccess,
  addImageCategoryFail,
  addImageCategorySuccess,
  changeStatusCategoryFail,
  changeStatusCategorySuccess,
  updateCategorySuccess,
  updateCategoryFail,
  deleteCategorySuccess,
  deleteCategoryFail,
} from "./category.actions"

//Include Both Helper File with needed methods
import CategoriesService from "services/categories/categories.service"

function* fetchCategories() {
  try {
    const response = yield call(CategoriesService.getCategories)
    if (response.status === 200) {
      yield put(getCategoriesSuccess(response.data.data))
      
    }
  } catch (error) {
    yield put(getCategoriesFail(error.response))
  }
}

function* onAddNewCategory({ payload: { category, history } }) {
  try {
    const response = yield call(CategoriesService.addNewCategory, category)
    yield put(addCategorySuccess(response.data))
    toast.success('Categoría creada satisfactoriamente.')
    history.push('/categories')
  } catch (error) {
    yield put(addCategoryFail(error))
  }
}

function* onAddImageCategory({ payload: { image, history } }) {
  
  try {
    const response = yield call(CategoriesService.addImageCategory, image)
    yield put(addImageCategorySuccess(response.data))
    history.push('/categories')
  } catch (error) {
    yield put(addImageCategoryFail(error))
  }
}

function* onCangeStatusCategory({ payload: { category, history, id} }) {
  try {
    const response = yield call(CategoriesService.changeStatus, id, category)
    
      yield put(changeStatusCategorySuccess(response.data))
      toast.success('Category actualizado satisfactoriamente.')
    
    history.push('/categories')
  } catch (error) {
    yield put(changeStatusCategoryFail(error))
  }
}

function* onUpdateCategory({ payload: { category, history, id } }) {
  try {
    const response = yield call(CategoriesService.updateCategory,id, category)
    yield put(updateCategorySuccess(response.data))
    history.push('/categories')
  } catch (error) {
    yield put(updateCategoryFail(error))
  }
}

function* onDeleteCategory({ payload: { categoryId, history} }) {
  try {
    const response = yield call(CategoriesService.deleteCategory, categoryId)
    yield put(deleteCategorySuccess(response.data))
    toast.success('Category actualizado satisfactoriamente.')
    history.push('/categories')
  } catch (error) {
    yield put(deleteCategoryFail(error))
  }
}

function* categorySaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(ADD_NEW_CATEGORY, onAddNewCategory)
  yield takeEvery(ADD_IMAGE_CATEGORY, onAddImageCategory)
  yield takeEvery(UPDATE_STATUS_CATEGORY, onCangeStatusCategory)
  yield takeEvery(UPDATE_CATEGORY, onUpdateCategory)
  yield takeEvery(DELETE_CATEGORY, onDeleteCategory)
}

export default categorySaga
