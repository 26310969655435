import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';

import UserList from './users-list';
import UsersCreate from './users-create';
import UsersOverview from './users-overview';

import { Button } from 'reactstrap';

const Users = props => {
  const { match } = props
  const { path, url } = match

  return (
    <React.Fragment>
      <div className="page-content">
        
        <div className="clearfix" style={{ padding: '.5rem' }}>
          {url !== path && (
            <Link to={`${path}`}>
              <Button color="secondary" className="float-left">
                Volver
              </Button>
            </Link>
          )}
          <Link to={`${path}/*/create`}>
            <Button color="primary" className="float-right">
              Nuevo
            </Button>
          </Link>
        </div>

        <Switch>
             <Route path={`${path}/create`}>
               <UsersCreate {...props} />
             </Route>
             <Route path={`${path}/edit/:id`}>
               <UsersCreate {...props} />
             </Route>
             <Route path={`${path}/delete/:id`}></Route>  
             <Route path={`${path}/:id`}>
               <UsersOverview {...props} />
             </Route>

             <Route exact path={path}>
               {/* <UsersGrid /> */}
               <UserList {...props} />
             </Route>
           </Switch>
      </div>
    </React.Fragment>
    
  )
}

export default Users
