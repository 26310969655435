//REGISTER
export const POST_REGISTER = "/register"

//LOGIN
export const POST_LOGIN = "/login"
export const POST_PASSWORD_FORGET = "/forget-pwd"
export const POST_LOGOUT = "/logout"

// REFRESH TOKEN
export const POST_REFRESH_TOKEN = "/refresh"

//PROFILE
export const GET_ME = "/profile"
export const POST_EDIT_PROFILE = "/post-profile"
