import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import App from './App';
import * as serviceWorker from './serviceWorker';

import { history } from './helpers/history'
import store from './store'
import setup from './services/setupInterceptors';

const app = (
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);

setup(store);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
