import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import {
  Badge,
  Button,
  Card,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  Spinner,
} from 'reactstrap'
import { AiFillDelete, AiFillEdit, AiFillEye } from 'react-icons/ai'
import Breadcrumbs from 'layouts/Common/Breadcrumb'
import { getUsers, deleteUser } from 'store/users/actions'
import { toast } from 'react-toastify'
import { Pagination } from 'react-pagination-bar'
import 'react-pagination-bar/dist/index.css'

const UsersList = props => {
  const [currentPage, setCurrentPage] = useState(1)
  const pageLimit = 25
  const { match, history } = props
  const { path } = match

  const dispatch = useDispatch()
  const { users, loading, error } = useSelector(state => state.Users)

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  useEffect(() =>error && toast.error(error) , [error])

  if (loading) {
    return (
      <Spinner color="primary" size="">
        Cargando...
      </Spinner>
    )
  }
  const Datas = users.length ? users : []

  const renderRole = role => {
    switch (role) {
      case 'user':
        return 'USUARIO'
      case 'writer':
        return 'EDITOR'
      case 'admin':
        return 'ADMINISTRADOR'
      default:
        return 'sin asignar'
    }
  }

  const handelDelete = id => {
    if (window.confirm(`¿Está seguro de querer eliminiar el registro ${id}?`)) {
      dispatch(deleteUser(Number(id), history))
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Lista de Usuarios | SAP - Flial Santa Fe</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title="Usuarios" breadcrumbItem="Lista de Usuarios" />
        <Row>
          <div className="col">
            <Card className="shadow">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Usario</th>
                    <th scope="col">Apellido</th>
                    <th scope="col">Nombres</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Email</th>
                    <th scope="col">Celular</th>
                    <th scope="col">Rol</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {Datas.slice(
                    (currentPage - 1) * pageLimit,
                    currentPage * pageLimit
                  ).map((data, i) => (
                    <tr key={i}>
                      <td>{data.id}</td>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="/colegio/alu/courses/1"
                          >
                            {/* <img
                                alt="..."
                                src={
                                  require(`assets/img/theme/${data.imgUrl}`)
                                    .default
                                }
                              /> */}
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              {data.username}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{data.last_name}</td>
                      <td>{data.first_name}</td>
                      <td>
                        {data.status ? (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            activo
                          </Badge>
                        ) : (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-error" />
                            inactivo
                          </Badge>
                        )}
                      </td>
                      <td>
                        <span className="mb-0 text-sm">{data.email}</span>
                      </td>
                      <td>
                        <span className="mb-0 text-sm">{data.phone_number}</span>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{renderRole(data.role)}</span>
                        </div>
                      </td>
                      <td className="text-right">
                        <Button
                          color="danger"
                          size="lg"
                          onClick={() => handelDelete(data.id)}
                        >
                          <AiFillDelete />
                        </Button>
                        {'  '}
                        <Link to={`${path}/*/edit/${data.id}`}>
                          <Button color="primary" size="lg">
                            <AiFillEdit />
                          </Button>
                        </Link>
                        {'  '}
                        <Link to={`${path}/*/${data.id}`}>
                          <Button color="secondary" size="lg">
                            <AiFillEye />
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Pagination
                  initialPage={currentPage}
                  itemsPerPage={pageLimit}
                  onPageСhange={pageNumber => setCurrentPage(pageNumber)}
                  totalItems={users.length}
                  pageNeighbours={2}
                  withProgressBar={true}
                  startLabel={'<<'}
                  endLabel={'>>'}
                  nextLabel={'>'}
                  prevLabel={'<'}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default UsersList
