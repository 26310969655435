import {
  GET_CATEGORIES,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  ADD_NEW_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  ADD_IMAGE_CATEGORY,
  ADD_IMAGE_CATEGORY_SUCCESS,
  ADD_IMAGE_CATEGORY_FAIL,
  UPDATE_STATUS_CATEGORY,
  UPDATE_STATUS_CATEGORY_SUCCESS,
  UPDATE_STATUS_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
} from './actionTypes'

// list
export const getCategories = () => ({
  type: GET_CATEGORIES,
})

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
})

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})
// end list \\

// add
export const addNewCategory = (category, history) => ({
  type: ADD_NEW_CATEGORY,
  payload: {
    category,
    history,
  },
})

export const addCategorySuccess = category => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: category,
})

export const addCategoryFail = error => ({
  type: ADD_CATEGORY_FAIL,
  payload: error,
})
// end add \\

// add IMAGE
export const addImageCategory = (image, history) => ({
  type: ADD_IMAGE_CATEGORY,
  payload: {
    image,
    history,
  },
})

export const addImageCategorySuccess = image => ({
  type: ADD_IMAGE_CATEGORY_SUCCESS,
  payload: image,
})

export const addImageCategoryFail = error => ({
  type: ADD_IMAGE_CATEGORY_FAIL,
  payload: error,
})
// end add IMAGE\\

// UPDATE STATUS
export const changeStatusCategory = (category, history, id) => ({
  type: UPDATE_STATUS_CATEGORY,
  payload: {
    category,
    history,
    id,
  },
})

export const changeStatusCategorySuccess = category => ({
  type: UPDATE_STATUS_CATEGORY_SUCCESS,
  payload: category,
})

export const changeStatusCategoryFail = error => ({
  type: UPDATE_STATUS_CATEGORY_FAIL,
  payload: error,
})
// end UPDATE STATUS\\

// edit
export const updateCategory = (category, history, id) => ({
  type: UPDATE_CATEGORY,
  payload: {
    category,
    history,
    id,
  },
})

export const updateCategorySuccess = category => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: category,
})

export const updateCategoryFail = error => ({
  type: UPDATE_CATEGORY_FAIL,
  payload: error,
})
// end edit \\

// delete
export const deleteCategory = (categoryId, history) => ({
  type: DELETE_CATEGORY,
  payload: {
    categoryId,
    history,
  },
})

export const deleteCategorySuccess = categoryId => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: categoryId,
})

export const deleteCategoryFail = error => ({
  type: DELETE_CATEGORY_FAIL,
  payload: error,
})
// end delete \\
