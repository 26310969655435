import React from 'react'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import { Col } from 'react-bootstrap'

const BlogCard = props => {
  const { noticia } = props
  return (
    <Col lg="6" md="12" >
      <div className="blog-item">
        <div className="blog-img">
          <Link to={process.env.PUBLIC_URL + '/noticias/' + noticia.slug}>
            <img
              src={noticia.image_url}
              alt=""
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="blog-content">
          <div className="blog-auth_date d-flex">
            <div className="author-img d-flex">
              
              <p>
                  {noticia.author_name}
              </p>
            </div>
            <div className="post-date">
              <p>
                <i className="las la-calendar"></i> 
                <Moment format="DD/MM/YYYY">{noticia.created_at}</Moment>
              </p>
            </div>
            <div className="post-date">
              <p>
                <i className="las la-calendar"></i> {noticia.id}
              </p>
            </div>
          </div>
          <div className="blog-title">
            <h6>
              <Link to={process.env.PUBLIC_URL + '/noticas/' + noticia.slug}>
                {noticia.title}
              </Link>
            </h6>
          </div>
          <div className="blog-desc">
            <p>{noticia.excerpt}</p>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default BlogCard
