import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Spinner,
  Alert,
} from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { useDispatch, useSelector } from 'react-redux'
import AvInput from 'availity-reactstrap-validation/lib/AvInput'
// import Dropzone from 'react-dropzone'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Breadcrumbs from 'layouts/Common/Breadcrumb'
// actions
import { addNewUser, updateUser } from 'store/users/actions'
const initialUserState = {
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  postal_address: '',
  subscribe: 0,
  role: '',
  status: '',
}

const UsersCreate = props => {
  const { error, loading } = props
  const [userForm, setUserForm] = useState(initialUserState)
  const [editMode, setEditMode] = useState(false)
  const { username, first_name, last_name, email, phone_number, postal_address, subscribe, role, status } = userForm
  const { id } = useParams()
  const { users } = useSelector(state => state.Users)
  const { user } = useSelector(state => state.Login.user)
  // const handleInputChange = event => {
  //   const { name, value } = event.target
  //   setUserForm({ ...userForm, [name]: value })
  // }
  const dispatch = useDispatch()
  useEffect(() => {
    if (id) {
      const singleUser = users.find(user => user.id === Number(id))
      setUserForm({ ...singleUser })
      setEditMode(true)
    } else {
      setEditMode(false)
      setUserForm({ ...initialUserState })
    }
  }, [id, users])
  // const formatBytes = (bytes, decimals = 2) => {
  //   if (bytes === 0) return '0 Bytes';
  //   const k = 1024;
  //   const dm = decimals < 0 ? 0 : decimals;
  //   const sizes = ['Bytes', 'KB', 'MB'];

  //   const i = Math.floor(Math.log(bytes) / Math.log(k));
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  // }

  // const handleAcceptedFiles = (files) => {
  //   console.log({ files });
  //   files.map((file) =>
  //     Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       formattedSize: formatBytes(file.size),
  //     })
  //   );

  //   setState({ selectedFiles: files });
  // }

  const handleValidSubmit = (e, values) => {
    e.preventDefault()
    if (editMode) {
      dispatch(updateUser(values, props.history, id))
      setEditMode(false)
      
    } else {
      dispatch(addNewUser(values, props.history))
      toast.success('Usuario agregado satisfactoriamente.')
    }
    props.history.goBack()
  }

  return (
    <React.Fragment>
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs
          title="Usuarios"
          breadcrumbItem={!editMode ? 'Nuevo Usuario' : 'Editar Usuario'}
        />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Crear nuevo usuario</CardTitle>
                <AvForm onValidSubmit={handleValidSubmit}>
                  {error ? <Alert color="danger">{error}</Alert> : null}
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="username" className="col-form-label col-lg-2">
                      Nombre de usuario
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="username"
                        name="username"
                        type="text"
                        className="form-control"
                        value={username || ''}
                        placeholder="Ingresar un nombre"
                        required
                        errorMessage="Debe ingresar un nombre válido..."
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="first_name" className="col-form-label col-lg-2">
                      Nombre
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="first_name"
                        name="first_name"
                        type="text"
                        className="form-control"
                        value={first_name || ''}
                        placeholder="Ingresar un nombre"
                        required
                        errorMessage="Debe ingresar un nombre válido..."
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="last_name"
                      className="col-form-label col-lg-2"
                    >
                      Apellido
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="last_name"
                        name="last_name"
                        type="text"
                        className="form-control"
                        value={last_name || ''}
                        placeholder="Ingresar apellido"
                        required
                        errorMessage="Debe ingresar un apellido válido..."
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="last_name"
                      className="col-form-label col-lg-2"
                    >
                      Email
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="email"
                        name="email"
                        type="email"
                        className="form-control"
                        value={email || ''}
                        placeholder="Ingresar email"
                        required
                        errorMessage="Debe ingresar un email válido..."
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="phone_number"
                      className="col-form-label col-lg-2"
                    >
                      Celular
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="phone_number"
                        name="phone_number"
                        type="text"
                        className="form-control"
                        value={phone_number || ''}
                        placeholder="Ingresar celular"
                        errorMessage="Debe ingresar un celular válido..."
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="postal_address"
                      className="col-form-label col-lg-2"
                    >
                      Domicilio
                    </Label>
                    <Col lg="10">
                      <AvInput
                        className="form-control"
                        id="postal_address"
                        rows="3"
                        name="postal_address"
                        value={postal_address || ''}
                        placeholder="Ingresar un domicilio"
                      ></AvInput>
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="subscribe"
                      className="col-form-label col-lg-2"
                    >
                      ¿Subscripto al Boletín?
                    </Label>
                    <Col lg="8">
                      <AvField
                        id="subscribe"
                        name="subscribe"
                        type="checkbox"
                        // trueValue="1" 
                        // falseValue="0"
                        value={subscribe || '0'}
                        style={{ marginLeft: 0 }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="status"
                      className="col-form-label col-lg-2"
                    >
                      ¿Activo?
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="status"
                        name="status"
                        type="checkbox"
                        trueValue="1" 
                        falseValue="0"
                        value={status || '0'}
                        style={{ marginLeft: 0 }}
                      />
                    </Col>
                  </FormGroup>
                  {user.role === 'admin' ? (
                    <FormGroup className="mb-3 mt-3 mt-lg-0 select2-container" row>
                        <Label
                          htmlFor="role"
                          className="col-form-label col-lg-3"
                        >
                          Rol
                        </Label>
                        <AvField
                          id="role"
                          name="role"
                          type="select"
                          className="form-select"
                          value={role || 'user'}
                          required
                          errorMessage="Debe ingresar un rol válido..."
                        >
                          <option key="1" value="user">
                            USUARIO
                          </option>
                          <option key="2" value="writer">
                            EDITOR
                          </option>
                          <option key="3" value="admin">
                            ADMINISTRADOR
                          </option>
                        </AvField>
                      </FormGroup>

                  ) : null}
                  {/* <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Imagen
                        </Label>
                        <Col lg="10">
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              this.handleAcceptedFiles(acceptedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                    </div>
                                    <h4>Archivos</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {state.selectedFiles &&
                              state.selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + '-file'}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                );
                              })}
                          </div>
                        </Col>
                      </FormGroup> */}
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button type="submit" color="primary" disabled={loading}>
                        {!editMode ? 'Guardar' : 'Actualizar'}
                        {loading && <Spinner color="info" size="sm" />}
                      </Button>{' '}
                      <Button
                        onClick={e => props.history.goBack()}
                        color="danger"
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default UsersCreate
