import {
  GET_EVENTS,
  GET_EVENTS_FAIL,
  GET_EVENTS_SUCCESS,
  ADD_NEW_EVENT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  ADD_IMAGE_EVENT,
  ADD_IMAGE_EVENT_SUCCESS,
  ADD_IMAGE_EVENT_FAIL,
  UPDATE_STATUS_EVENT,
  UPDATE_STATUS_EVENT_SUCCESS,
  UPDATE_STATUS_EVENT_FAIL,
  UPDATE_EVENT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
} from './actionTypes'

// list
export const getEvents = () => ({
  type: GET_EVENTS,
})

export const getEventsSuccess = events => ({
  type: GET_EVENTS_SUCCESS,
  payload: events,
})

export const getEventsFail = error => ({
  type: GET_EVENTS_FAIL,
  payload: error,
})
// end list \\

// add
export const addNewEvent = (event, history) => ({
  type: ADD_NEW_EVENT,
  payload: {
    event,
    history,
  },
})

export const addEventSuccess = event => ({
  type: ADD_EVENT_SUCCESS,
  payload: event,
})

export const addEventFail = error => ({
  type: ADD_EVENT_FAIL,
  payload: error,
})
// end add \\

// add IMAGE
export const addImageEvent = (image, history) => ({
  type: ADD_IMAGE_EVENT,
  payload: {
    image,
    history,
  },
})

export const addImageEventSuccess = image => ({
  type: ADD_IMAGE_EVENT_SUCCESS,
  payload: image,
})

export const addImageEventFail = error => ({
  type: ADD_IMAGE_EVENT_FAIL,
  payload: error,
})
// end add IMAGE\\

// UPDATE STATUS
export const changeStatusEvent = (event, history, id) => ({
  type: UPDATE_STATUS_EVENT,
  payload: {
    event,
    history,
    id,
  },
})

export const changeStatusEventSuccess = event => ({
  type: UPDATE_STATUS_EVENT_SUCCESS,
  payload: event,
})

export const changeStatusEventFail = error => ({
  type: UPDATE_STATUS_EVENT_FAIL,
  payload: error,
})
// end UPDATE STATUS\\

// edit
export const updateEvent = (event, history, id) => ({
  type: UPDATE_EVENT,
  payload: {
    event,
    history,
    id,
  },
})

export const updateEventSuccess = event => ({
  type: UPDATE_EVENT_SUCCESS,
  payload: event,
})

export const updateEventFail = error => ({
  type: UPDATE_EVENT_FAIL,
  payload: error,
})
// end edit \\

// delete
export const deleteEvent = (eventId, history) => ({
  type: DELETE_EVENT,
  payload: {
    eventId,
    history,
  },
})

export const deleteEventSuccess = eventId => ({
  type: DELETE_EVENT_SUCCESS,
  payload: eventId,
})

export const deleteEventFail = error => ({
  type: DELETE_EVENT_FAIL,
  payload: error,
})
// end delete \\
