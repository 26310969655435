import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import NewsList from './news-list'
import NewsCreate from './news-create'

import { getAllNews, getCategories } from 'store/actions'

export const News = props => {
  const { match } = props
  const { path } = match
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllNews())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  return (
    <>
      <Switch>
        {/* NEWS */}
        <Route path={`${path}/create`}>
          <NewsCreate {...props} />
        </Route>
        <Route path={`${path}/:id/edit`}>
          <NewsCreate {...props} />
        </Route>
        <Route path={`${path}/:id`}>{/* <UsersOverview {...props} /> */}</Route>

        <Route path={path} component={NewsList} {...props} />
      </Switch>
    </>
  )
}

export default News
