import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Moment from 'react-moment';
import { Styles } from "./styles/homeBlog";

import newsService from 'services/news/news.service';

class HomeBlog extends Component {
    state = {
        posts: [],
      }
    
      componentDidMount() {
        
        newsService.getLastPublished()
          .then(res => res.data)
          .then(data => {
            this.setState({ posts: data.data })
          })
      }

    render() {
        return (
            <Styles>
                {/* Blog Area */}
                <section className="home-blog-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>Ultimas Novedades de la Institución</h4>
                                </div>
                            </Col>
                            {
                                this.state.posts.slice(0, 6).map((data, i) => (
                                    <Col md="6" key={i}>
                                        <div className="blog-post">
                                            <Row>
                                                <Col lg="6" md="12">
                                                    <div className="blog-img">
                                                        <Link to={process.env.PUBLIC_URL + '/noticias/' + data.slug}><img src={data.image_url} alt="imagen noticia" className="img-fluid" height={100}/></Link>
                                                    </div>
                                                </Col>
                                                <Col lg="6" md="12">
                                                    <div className="blog-content">
                                                        <div className="content-box">
                                                            <div className="top-content d-flex">
                                                                <div className="blog-date text-center">
                                                                    <p>
                                                                    <Moment format="DD/MM/YYYY">{data.created_at}</Moment>

                                                                    </p>
                                                                </div>
                                                                <div className="blog-title">
                                                                    <h6><Link to={process.env.PUBLIC_URL + '/noticias/' + data.slug}>{data.title}</Link></h6>
                                                                </div>
                                                            </div>
                                                            <div className="blog-desk">
                                                                <p>{data.excerpt}</p>
                                                                <ul className="list-unstyled list-inline">
                                                                    <li className="list-inline-item"><i className="las la-user"></i> {data.author_name}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default HomeBlog
