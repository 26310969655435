import React from 'react'
import { Link } from 'react-router-dom'

const Logo = (props) => {
  const { width, height } = props
  return (
    <div className="logo">
      <Link to={process.env.PUBLIC_URL + '/'}>
        <img
          src={process.env.PUBLIC_URL + '/assets/images/colegio.webp'}
          alt="Logo siteweb"
          width={width ? width : 200}
          height={height ? height : ''}
        />
      </Link>
    </div>
  )
}

export default Logo
