import React, { useEffect } from 'react'
import { Container, Row, Card, Button } from 'reactstrap'
import MetaTags from 'react-meta-tags'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { AiFillDelete, AiFillEdit } from 'react-icons/ai'
import Swal from 'sweetalert2'

import { getCategories, deleteCategory } from 'store/actions'
//Import Breadcrumb
import Breadcrumbs from 'layouts/Common/Breadcrumb'
import DataTableBase from 'pages/admin/components/DataTableBase'
// import cloudService from 'services/cloud.service'

const CategoriesList = props => {
  const { match, history } = props
  const { path, url } = match

  const dispatch = useDispatch()
  let categories = useSelector(state => state.Category.categories)
  const { error } = useSelector(state => state.Category)
  const perfil = useSelector(state => state.Login.user.user)
  const role = perfil.role

  useEffect(() => {
    dispatch(getCategories())
  }, [])

  useEffect(() => error && toast.error(error), [error])

  const Datas = categories?.length ? categories : []

  const handelDelete = (id, img_id) => {
    Swal.fire({
      title: `¿Está seguro de querer eliminiar el registro ${id}?`,
      text: 'Luego no se podra revertir la acción!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, elimiar!',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteCategory(Number(id), history))
      }
    })
  }

  const acciones = row => {
    return (
      <>
        {role === 'admin' ? (
          <>
            <Link to={`${path}/catetories/${row.id}/edit`}>
              <Button color="primary" size="lg">
                <AiFillEdit />
              </Button>
            </Link>
            <Button
              color="danger"
              size="lg"
              onClick={() => handelDelete(row.id, row.image_id)}
            >
              <AiFillDelete />
            </Button>
          </>
        ) : null}
      </>
    )
  }

  const rowTitle = row => {
    return (
      <>
        {role === 'admin' ? (
          <Link to={`${path}/categories/${row.id}/edit`}>{row.title}</Link>
        ): row.title }
      </>
    )
  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: false,
      right: true,
    },
    {
      name: 'Título',
      cell: row => rowTitle(row),
      sortable: true,
      grow: 2,
    },
    {
      name: 'Slug',
      selector: row => row.slug,
      wrap: true,
      sortable: false,
      format: row => `${row.slug.slice(0, 150)}...`,
    },
    {
      name: 'Noticias',
      sortable: false,
      selector: row => (row.news ? row.news.length : 0),
    },
    {
      name: 'Cursos',
      sortable: false,
      selector: row => (row.courses ? row.courses.length : 0),
    },
    {
      name: 'Eventos',
      sortable: false,
      selector: row => (row.events ? row.events.length : 0),
    },
    {
      name: 'Acciones',
      cell: row => acciones(row),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Categorias | NS Guadalupe Santa Fe</title>
        </MetaTags>
        <Container fluid>
          {perfil.role === 'admin' && (
            <div className="clearfix" style={{ padding: '.5rem' }}>
              {url !== path && (
                <Link to={`${path}/categories`}>
                  <Button color="secondary" className="float-left">
                    Volver
                  </Button>
                </Link>
              )}
              <Link to={`${path}/categories/create`}>
                <Button color="primary" className="float-right">
                  Nuevo
                </Button>
              </Link>
            </div>
          )}
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Categorías"
            breadcrumbItem="Lista de categorías"
          />

          <Row>
            <div className="col">
              <Card className="shadow">
                <DataTableBase columns={columns} data={Datas} />
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CategoriesList
