import api from 'config/api'
import * as url from './url_helper'

class UsersService {

  getMe () {
    return api.post(url.GET_ME)
  }

  // get contacts
  getUsers () { 
    return api
      .get(url.GET_USERS)
  }

// add user
  addNewUser ( user ) { 
    return api.post(url.ADD_NEW_USER, user)
  }

// update user
  updateUser ( id, user ) { 
    return api.put(url.UPDATE_USER + `/${id}`, user)
  }

// delete user
  deleteUser ( userId ) {
    return api.delete(`${url.DELETE_USER}/${userId}`)
  }

  getUserProfile () { 
    return api.get(url.GET_USER_PROFILE)
  }

  updateRole (id, role) { 
    return api.put(url.UPDATE_ROLE + `/${id}`, role)
  }

  setActive (userId) {
    return api.put(`${url.CHANGE_ACTIVO}/${userId}`)
  }

  setInactive (userId) {
    return api.put(`${url.CHANGE_ACTIVO}/${userId}`)
  }
}

export default new UsersService()