import React from 'react'
import { Link } from 'react-router-dom'

const FooterLogoInfo = () => {
  return (
    <div className="f-links">
      <h5>Enlaces útiles</h5>
      <ul className="list-unstyled">
        <li>
          <a target="blank" href="https://www.fic.org.ar">
            <i className="las la-angle-right"></i>Hijas de la Inmaculada
            Concepción
          </a>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + '/administracion'}>
            <i className="las la-angle-right"></i>Administración
          </Link>
        </li>
        <li>
          <Link
            to={process.env.PUBLIC_URL + '/assets/files/IdearioFIC_SFe.pdf'}
          >
            <i className="las la-angle-right"></i>Ideario
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + '/faqs'}>
            <i className="las la-angle-right"></i>Preguntas Frecuentes
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default FooterLogoInfo
