import React from "react";
import { Redirect } from "react-router-dom";

import Dashboard from "pages/admin/dash";
import Usuarios from "pages/admin/users";
import Banners from "pages/admin/banners";
import UserProfile from "pages/admin/users/Profile";
import News from "pages/admin/news";
import Events from "pages/admin/events";
import Categories from "pages/admin/category";
// import Boletines from "pages/admin/boletines";
// import Contactos from "pages/admin/contactos";

const authProtectedRoutes = [
  { path: '/_/dashboard', component: Dashboard },
  { path: '/_/profile', component: UserProfile },
  { path: '/_/usuarios', component: Usuarios },
  { path: '/_/usuarios/*', component: Usuarios },
  { path: '/_/banners', component: Banners },
  { path: '/_/banners/*', component: Banners },
  { path: '/_/categories', component: Categories },
  { path: '/_/categories/*', component: Categories },
  { path: '/_/news', component: News },
  { path: '/_/news/*', component: News },
  { path: '/_/events', component: Events },
  { path: '/_/events/*', component: Events },
  // { path: '/_/boletines', component: Boletines },
  // { path: '/_/contacts', component: Contactos },
  
  // Default
  { path: '/_', exact: true, component: ()=> <Redirect to="/_/dashboard" /> },

  { path: '/_/*', exact: true, component: ()=> <Redirect to="/_/dashboard" /> },
  // { path: '/_/**', component: AdminPageNotFound },
];

export default authProtectedRoutes;