/* PROFS_CATEGORIES */
export const GET_CATEGORIES = "GETS_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GETS_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GETS_CATEGORIES_FAIL"

export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY"
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS"
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL"

export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL"

export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL"

export const GET_CATEGORYILE = "GET_CATEGORYILE"
export const GET_CATEGORYILE_SUCCESS = "GET_CATEGORYILE_SUCCESS"
export const GET_CATEGORYILE_FAIL = "GET_CATEGORYILE_FAIL"

export const ADD_IMAGE_CATEGORY = "ADD_IMAGE_CATEGORY"
export const ADD_IMAGE_CATEGORY_SUCCESS = "ADD_IMAGE_CATEGORY_SUCCESS"
export const ADD_IMAGE_CATEGORY_FAIL = "ADD_IMAGE_CATEGORY_FAIL"

export const UPDATE_STATUS_CATEGORY = "UPDATE_STATUS_CATEGORY"
export const UPDATE_STATUS_CATEGORY_SUCCESS = "UPDATE_STATUS_CATEGORY_SUCCESS"
export const UPDATE_STATUS_CATEGORY_FAIL = "UPDATE_STATUS_CATEGORY_FAIL"