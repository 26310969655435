import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import HeaderTwo from 'components/HeaderTwo'
import { BreadcrumbBox } from 'components/common/Breadcrumb'
import GoogleMap from './GoogleMap'
import Footer from 'components/Footer'
import { Styles } from './styles/contact'

function Contact() {
  useEffect(() => {
    const form = document.getElementById('form_contact')
    const name = document.getElementById('contact_name')
    const email = document.getElementById('contact_email')
    const subject = document.getElementById('contact_subject')
    const message = document.getElementById('contact_message')

    form.addEventListener('submit', formSubmit)

    function formSubmit(e) {
      e.preventDefault()

      const nameValue = name.value.trim()
      const emailValue = email.value.trim()
      const subjectValue = subject.value.trim()
      const messageValue = message.value.trim()

      if (nameValue === '') {
        setError(name, "El nombre no puede estar en blanco")
      } else {
        setSuccess(name)
      }

      if (emailValue === '') {
        setError(email, "Email no puede estar en blanco")
      } else if (!isEmail(emailValue)) {
        setError(email, 'No es un email válido')
      } else {
        setSuccess(email)
      }

      if (subjectValue === '') {
        setError(subject, "Asunto no puede estar en blanco")
      } else {
        setSuccess(subject)
      }

      if (messageValue === '') {
        setError(message, "Mensaje no puede estar en blanco")
      } else {
        setSuccess(message)
      }
    }

    function setError(input, message) {
      const formControl = input.parentElement
      const errorMsg = formControl.querySelector('.contact_input-msg')
      formControl.className = 'form-control text-left error'
      errorMsg.innerText = message
    }

    function setSuccess(input) {
      const formControl = input.parentElement
      formControl.className = 'form-control success'
    }

    function isEmail(email) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)
    }
  })

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper contact-page">
        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Contacto" />

        {/* Contact Area */}
        <section className="contact-area">
          <Container>
            <Row>
              <Col md="4">
                <div className="contact-box-title">
                  <h4>Contacto</h4>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-map-marker"></i>
                  </div>
                  <div className="box-content">
                    <h5>Domicilio</h5>
                    <p>9 de Julio 2558, Santa Fe, Argentina, CP 3000.</p>
                  </div>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-envelope-open"></i>
                  </div>
                  <div className="box-content">
                    <h5>Email</h5>
                    <p>
                      contacto@colegionsguadalupe.edu.ar
                      <br />
                      inscripciones@colegionsguadalupe.edu.ar
                    </p>
                  </div>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <i className="las la-phone"></i>
                  </div>
                  <div className="box-content">
                    <h5>Teléfono</h5>
                    <p>
                      +54 (0342) 452-5489
                      <br />
                      +54 (0342) 455-9546
                    </p>
                  </div>
                </div>
                <div className="contact-social">
                  <ul className="social list-unstyled list-inline">
                    {/* <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + '/'}>
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + '/'}>
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + '/'}>
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + '/'}>
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href={process.env.PUBLIC_URL + '/'}>
                        <i className="fab fa-dribbble"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </Col>
              <Col md="8">
                <div className="contact-form">
                  <div className="form-title">
                    <h4>Ponerse en contacto</h4>
                  </div>
                  <div className="form-box">
                    <form id="form_contact" className="form">
                      <Row>
                        <Col md="6">
                          <p className="form-control">
                            <input
                              type="text"
                              placeholder="Nombre y Apellido"
                              id="contact_name"
                            />
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="6">
                          <p className="form-control">
                            <input
                              type="email"
                              placeholder="Email"
                              id="contact_email"
                            />
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="12">
                          <p className="form-control">
                            <input
                              type="text"
                              placeholder="Asunto"
                              id="contact_subject"
                            />
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="12">
                          <p className="form-control">
                            <textarea
                              name="message"
                              id="contact_message"
                              placeholder="Ingrese su mensaje"
                            ></textarea>
                            <span className="contact_input-msg"></span>
                          </p>
                        </Col>
                        <Col md="12">
                          <button>Enviar Mensaje</button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          {/* Google Map */}
          <GoogleMap />
        </section>

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  )
}

export default Contact
