import React, { useState, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
} from 'reactstrap'
import Breadcrumbs from 'layouts/Common/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useParams } from 'react-router'
import { updateNews, addNewNews } from 'store/actions'
import Ckeditor from 'react-ckeditor-component/lib/ckeditor'
import FileUpload from 'pages/admin/components/fileUpload/FileUpload'
import cloudService from 'services/cloud.service'

const initialNewsState = {
  title: '',
  slug: '',
  excerpt: '',
  content: '<p>Cargar el contenido en el <strong>editor</strong> 😀</p>',
  image_url: '',
  comments_open: '',
  publico: '',
  destacado: '',
  status: '',
  category_id: '',
  user_id: '',
  author_name: '',
  selectedFile: [],
}

const NewsCreate = props => {
  const news = useSelector(state => state.News.news)
  const categories = useSelector(state => state.Category.categories)
  const user = useSelector(state => state.Login.user.user)
  const { error, loading } = props
  const { id } = useParams()
  const [newsForm, setNewsForm] = useState(initialNewsState)
  const [editMode, setEditMode] = useState(false)

  const {
    title,
    slug,
    excerpt,
    content,
    comments_open,
    publico,
    destacado,
    status,
    tags,
    author_name,
    category_id,
    user_id,
  } = newsForm

  const dispatch = useDispatch()

  const updateUploadedFiles = files => {

    cloudService
      .imageUp(files[0], 'noticias')
      .then(res => res.json())
      .then(data => {
        const newNewsform = {
          ...newsForm,
          image_url: data.secure_url,
          img_data: data,
        }
        setNewsForm(newNewsform)
      })
  }

  function updateContent(newContent) {
    setNewsForm({ ...newsForm, content: newContent })
  }

  const onChange = evt => {
    var newContent = evt.editor.getData()
    updateContent(newContent)
  }

  const afterPaste = evt => {
    var newContent = evt.editor.getData()
    updateContent(newContent)
  }

  useEffect(() => {
    if (id) {
      const singleNews = news.find(news => news.id === Number(id))
      updateContent(singleNews.content)
      setNewsForm({ ...singleNews })
      setEditMode(true)
    } else {
      setEditMode(false)
      setNewsForm({ ...initialNewsState })
    }
    return () => {
      setNewsForm({ ...initialNewsState })
    }
  }, [id, news])

  const handleValidSubmit = (e, values) => {
    e.preventDefault()
    const allValues = { ...newsForm, ...values }

    if (editMode) {
      dispatch(updateNews(allValues, props.history, id))
      setEditMode(false)
    } else {
      dispatch(addNewNews(allValues, props.history))
    }
    props.history.goBack()
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {id ? 'Editar' : 'Nueva'} Noticia | Colegio NS Guadalupe - Santa Fe
        </title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs
          title="Noticias"
          breadcrumbItem={id ? 'Editar Noticia' : 'Nueva Noticia'}
        />
        <Row>
          <Col lg="12">
            <Card>
              <CardTitle className="mb-4">
                {id ? 'Editar Noticia' + slug : 'Nueva Noticia'}
              </CardTitle>
              <CardBody className="mb-4">
                <AvForm onValidSubmit={handleValidSubmit}>
                  {error ? <Alert color="danger">{error}</Alert> : null}
                  {id ? (
                    <FormGroup className="mb-4">
                      <Col lg="10">
                        <AvField
                          id="id"
                          name="id"
                          type="hidden"
                          className="form-control"
                          value={id || ''}
                        />
                      </Col>
                    </FormGroup>
                  ) : null}
                  <FormGroup className="mb-4">
                      <Col lg="10">
                        <AvField
                          id="user_id"
                          name="user_id"
                          type="hidden"
                          className="form-control"
                          value={user_id || user.id}
                        />
                      </Col>
                    </FormGroup>
                  <FormGroup className="mb-4">
                    <Label htmlFor="title" className="col-form-label col-lg-2">
                      Título
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="title"
                        name="title"
                        type="text"
                        className="form-control"
                        value={title || ''}
                        placeholder="Ingresar un nombre"
                        autoFocus={true}
                        required
                        errorMessage="Debe ingresar un nombre válido..."
                      />
                    </Col>
                  </FormGroup>
                  <Row>
                    <Col lg="5">
                      <FormGroup className="mb-4 select2-container">
                        <Label htmlFor="category_id" className="control-label">
                          Categoría
                        </Label>
                        <AvField
                          className="select2-selection"
                          type="select"
                          name="category_id"
                          value={category_id || ''}
                          required
                          errorMessage="Debe seleccionar una categoría."
                        >
                          <option key="0" value="">
                            Seleccionar Categoría
                          </option>
                          {categories?.map(data => (
                            <option key={data.id} value={data.id}>
                              {data.title}
                            </option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col lg="5">
                      <FormGroup className="mb-4 mt-3 mt-lg-0 select2-container">
                        <Label htmlFor="author_name" className="control-label">
                          Autor
                        </Label>
                        <AvField
                          type="text"
                          name="author_name"
                          value={author_name || ''}
                          required
                          errorMessage="Debe asignar un autor."
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="mb-4">
                    <Label
                      htmlFor="excerpt"
                      className="col-form-label col-lg-2"
                    >
                      Descipción
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="excerpt"
                        name="excerpt"
                        type="text"
                        className="form-control"
                        value={excerpt || ''}
                        placeholder="Ingresar una descripción"
                        required
                        errorMessage="Debe ingresar una descripción válida..."
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label htmlFor="tags" className="col-form-label col-lg-2">
                      Tags
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="tags"
                        name="tags"
                        type="text"
                        className="form-control"
                        value={tags ? tags.map(data => data.name) : ''}
                        placeholder="Ingresar tags separados por coma"
                        required
                        errorMessage="Debe ingresar al menos un tag..."
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label
                      htmlFor="content"
                      className="col-form-label col-lg-2"
                    >
                      Contenido
                    </Label>
                    <Col lg="10">
                      <Ckeditor
                        activeClass="p10"
                        content={content || ''}
                        events={{
                          // "blur": (e) => onBlur(e),
                          afterPaste: e => afterPaste(e),
                          change: e => onChange(e),
                        }}
                        required={true}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <FileUpload
                      accept=".jpg,.png,.jpeg"
                      label="Imagen de la Noticia"
                      // multiple
                      updateFilesCb={updateUploadedFiles}
                    />
                  </FormGroup>
                  <Row>
                    <Col lg="3">
                      <FormGroup className="mb-3">
                        <Label
                          htmlFor="status"
                          className="mb-3 mt-3 mt-lg-0 select2-container"
                        >
                          Estado
                        </Label>
                        <AvField
                          id="status"
                          name="status"
                          type="select"
                          className="form-select"
                          value={status || 'DRAFT'}
                          required
                          errorMessage="Debe ingresar un estado válido..."
                        >
                          <option key="1" value="DRAFT">
                            BORRADOR
                          </option>
                          <option key="2" value="PUBLISHED">
                            PUBLICADO
                          </option>
                          <option key="3" value="UNPUBLISHED">
                            DESPUBLICADO
                          </option>
                          <option key="4" value="DELETED">
                            ELIMINADO
                          </option>
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup className="mb-3" row>
                        <Label
                          htmlFor="comments_open"
                          className="form-check-Label"
                        >
                          ¿Acepta comentarios?
                        </Label>

                        <AvField
                          id="comments_open"
                          className=".form-check-warning"
                          name="comments_open"
                          type="checkbox"
                          value={comments_open || ''}
                          style={{ marginLeft: 0 }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup className="mb-3" row>
                        <Label
                          htmlFor="destacado"
                          className="form-check-Label"
                        >
                          ¿Destacada?
                        </Label>

                        <AvField
                          id="destacado"
                          className=".form-check-warning"
                          name="destacado"
                          type="checkbox"
                          value={destacado || ''}
                          style={{ marginLeft: 0 }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="3">
                      <FormGroup className="mb-3" row>
                        <Label 
                          htmlFor="publico" 
                          className="form-check-Label">
                          ¿Publica?
                        </Label>

                        <AvField
                          id="publico"
                          className=".form-check-danger"
                          name="publico"
                          type="checkbox"
                          value={publico || ''}
                          style={{ marginLeft: 0 }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button type="submit" color="primary" disabled={loading}>
                        {!editMode ? 'Guardar' : 'Actualizar'}
                        {loading && <Spinner color="info" size="sm" />}
                      </Button>{' '}
                      <Button
                        onClick={e => props.history.goBack()}
                        color="danger"
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NewsCreate
