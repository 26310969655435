import {
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  ADD_IMAGE_CATEGORY_SUCCESS,
  ADD_IMAGE_CATEGORY_FAIL,
  UPDATE_STATUS_CATEGORY_SUCCESS,
  UPDATE_STATUS_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
} from './actionTypes'

const INIT_STATE = {
  categories: {},
  error: {},
}

const categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      }

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state, action.payload],
        loading: false,
      }

    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case ADD_IMAGE_CATEGORY_SUCCESS:
      return {
        ...state,
        image: [...state, action.payload],
        loading: false,
      }

    case ADD_IMAGE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_STATUS_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.map(category =>
          category.id.toString() === action.payload.id.toString()
            ? { category, ...action.payload }
            : category
        ),
      }

    case UPDATE_STATUS_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.map(category =>
          category.id.toString() === action.payload.id.toString()
            ? { category, ...action.payload }
            : category
        ),
      }

    case UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.filter(
          category => category.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default categories
