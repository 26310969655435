import React, { Component } from 'react'
import { connect } from 'react-redux'
// MetisMenu
import MetisMenu from 'metismenujs'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {
  BiHomeCircle,
  BiNews,
  BiUserCircle,
  BiCategory,
} from 'react-icons/bi'
import { FaUsersCog } from 'react-icons/fa'

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.initMenu()
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu('#side-menu')

    var matchingMenuItem = null
    var ul = document.getElementById('side-menu')
    var items = ul.getElementsByTagName('a')
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add('active')
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show')
    }

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show')

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') //a
          const parent4 = parent3.parentElement
          if (parent4) {
            parent4.classList.add('mm-active')
          }
        }
      }
      return false
    }
    return false
  }

  render() {
    return (
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">Menú</li>
          <li>
            <Link to="/_/dashboard" className="waves-effect">
              <BiHomeCircle /> <span>Inicio</span>
            </Link>
          </li>
          {this.props.profile.role === 'admin' ? (
            <>
              <li className="menu-title">Administración</li>
              <li>
                <Link to="/_/usuarios">
                  <FaUsersCog /> <span>Usuarios</span>
                </Link>
              </li>
              <li>
                <Link to="/_/banners">
                  <BiUserCircle /> <span>Banner</span>
                </Link>
              </li>
              <li>
                <Link to="/_/categories">
                  <BiCategory /> Categorías
                </Link>
              </li>
              <li>
                <Link to="/_/news">
                  <BiNews /> Noticias
                </Link>
              </li>
              <li>
                <Link to="/_/events">
                  <BiNews /> Eventos
                </Link>
              </li>

              
              
            </>
          ) : null}

          <li className="menu-title">Usuarios</li>

          <li>
            <Link to="/_/profile">
              <BiUserCircle /> <span>Perfil</span>
            </Link>
          </li>
        </ul>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    profile: state.Login.user.user,
  }
}

export default connect(mapStateToProps, null)(withRouter(SidebarContent))
