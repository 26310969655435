import api from 'config/api'
import * as url from './url_helper'

class CategoriesService {

  getCategory (id) {
    return api.get(id)
  }

  getCoursesPublished() {
    return api
      .get(url.GET_COURSES_CATEGORIES_PUBLISHED)
  }

  getNewsPublished() {
    return api
      .get(url.GET_NEWS_CATEGORIES_PUBLISHED)
  }

  getEventsPublished() {
    return api
      .get(url.GET_EVENTS_CATEGORIES_PUBLISHED)
  }

  // get categorys
  getCategories () { 
    return api
      .get(url.GET_CATEGORIES)
  }

  getCategoriesSlug (slug) {
    return api.get(url.GET_CATEGORY_SLUG + '/' + slug)
  }

// add category
  addNewCategory ( category ) { 
    return api.post(url.ADD_NEW_CATEGORY, category)
  }

// update category
  updateCategory ( id, category ) { 
    return api.put(url.UPDATE_CATEGORY + `/${id}`, category)
  }

// delete category
  deleteCategory ( categoryId ) {
    return api.delete(`${url.DELETE_CATEGORY}/${categoryId}`)
  }
}

export default new CategoriesService()