import React, { useState, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import { Alert, Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Label, Row, Spinner } from 'reactstrap'
import Breadcrumbs from 'layouts/Common/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useParams } from 'react-router'
import { updateCategory, addNewCategory } from 'store/actions'

const initialCategoryState = {
  'title': '',
  'slug': '',
}

const CategoryCreate = (props) => {
  const {error, loading } = props
  const { id } = useParams()
  const [categoryForm, setCategoryForm] = useState(initialCategoryState)
  const [editMode, setEditMode] = useState(false)
  const categories = useSelector(state => state.Category.categories)
  const { title, slug } = categoryForm
  
  const dispatch = useDispatch()
  
  useEffect(() => {
    if(id) {
      const singleCategory = categories.find(category => category.id === Number(id))
      setCategoryForm({...singleCategory})
      setEditMode(true)
      console.log('hola mundo');
    } else {
      setEditMode(false)
      setCategoryForm({...initialCategoryState})
    }
    return () => {
      setCategoryForm({...initialCategoryState})
    }
  }, [id,categories])

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    
    if (editMode) {
      dispatch(updateCategory(values, props.history, id))
      setEditMode(false)
    } else {
      dispatch(addNewCategory(values, props.history))
    }
    props.history.goBack()
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>{id ? "Editar" : "Nueva"} Categoría | NS Guadalupe Santa Fe</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Utility" breadcrumbItem={id ? 'Editar Categoría' : 'Nueva Categoría'} />
        <Row>
          <Col lg="12">
            <Card>
              <CardTitle className='mb-4'>
                {id ? "Editar Categoría: " + slug : "Crear nueva Categoría" }
              </CardTitle>
              <CardBody className='mb-4'>
                <AvForm onValidSubmit={handleValidSubmit} >
                  {error ? <Alert color="danger">{error}</Alert> : null}

                {id ? 
                  <FormGroup className="mb-4" row>
                    <Col lg="10">
                      <AvField
                        id="id"
                        name="id"
                        type="hidden"
                        className="form-control"
                        value={id || ''}
                      />
                    </Col>
                  </FormGroup>
                : null}

                  <FormGroup className="mb-4" row>
                    <Label htmlFor="title" className="col-form-label col-lg-2">
                      Título
                    </Label>
                    <Col lg="10">
                      <AvField
                        id="title"
                        name="title"
                        type="text"
                        className="form-control"
                        value={title || ''}
                        placeholder="Ingresar un nombre"
                        autoFocus={true}
                        required
                        errorMessage="Debe ingresar un nombre válido..."
                      />
                    </Col>
                  </FormGroup>
                  
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button type="submit" color="primary" disabled={loading}>
                        {!editMode ? 'Guardar' : 'Actualizar'}
                        {loading && <Spinner color="info" size="sm" />}
                      </Button>{' '}
                      <Button
                        onClick={e => props.history.goBack()}
                        color="danger"
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CategoryCreate
