import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'

const EventCard = props => {
  const { event } = props
  return (
    <div className="event-box" key={event.id}>
      <Row>
        <Col xl="3" lg="4" md="0">
          <div className="event-img">
            <Link to={process.env.PUBLIC_URL + '/eventos/' + event.id}>
              <img
                src={
                  process.env.PUBLIC_URL + `/assets/images/${event.eventImg}`
                }
                alt=""
                className="img-fluid"
              />
            </Link>
          </div>
        </Col>
        <Col xl="9" lg="8" md="12">
          <div className="event-content">
            <div className="content-box">
              <Row>
                <Col md="9">
                  <div className="event-title">
                    <h5>{event.id}</h5>
                    <h6>
                      <Link to={process.env.PUBLIC_URL + '/eventos/' + event.id}>
                        {event.eventTitle}
                      </Link>
                    </h6>
                  </div>
                  <div className="event-time-location">
                    <ul className="list-unstyled list-inline">
                      <li className="list-inline-item">
                        <i className="las la-clock"></i> {event.eventTime}
                      </li>
                      <li className="list-inline-item">
                        <i className="las la-map-marker"></i>{' '}
                        {event.eventLocation}
                      </li>
                    </ul>
                  </div>
                  <div className="event-desc">
                    <p>{event.eventdesc}</p>
                  </div>
                </Col>
                <Col md="3" className="text-center">
                  <div className="event-date">
                    <p>{event.eventDate}</p>
                  </div>
                  <div className="join-btn">
                    <Link to={process.env.PUBLIC_URL + event.eventLink}>
                      Join Now
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default EventCard
