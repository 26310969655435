import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import EventsList from './events-list'
import EventCreate from './events-create'

import { getEvents } from 'store/actions'

export const Events = props => {
  const { match } = props
  const { path } = match
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEvents())
  }, [dispatch])

  return (
    <>
      <Switch>
        {/* ARTICLES */}
        <Route path={`${path}/create`}>
          <EventCreate {...props} />
        </Route>
        <Route path={`${path}/:id/edit`}>
          <EventCreate {...props} />
        </Route>
        <Route path={`${path}/:id`}>{/* <UsersOverview {...props} /> */}</Route>

        <Route path={path} component={EventsList} {...props} />
      </Switch>
    </>
  )
}

export default Events
