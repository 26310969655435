import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL + '/api'; // php server
// const apiURL = 'https://sapin.aguara.jjsoftsistemas.com/v1'; // node server

const instance = axios.create({
  baseURL: apiURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;