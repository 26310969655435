import api from 'config/api'
import TokenService from 'services/auth/token.service'
import * as url from './url_helper'

class AuthService {
  
  getLoggedInUser () {
    const user = localStorage.getItem('authUser');
    if (user) return JSON.parse(user);
    return null;
  }

  isUserAuthenticated = () => {
    return this.getLoggedInUser() !== null;
  }

  postLogin (bodyContent) {
    return api
      .post(url.POST_LOGIN, bodyContent)
      .then(response => {
        const user = response.data.data
        if(user.token) {
          TokenService.setUser(user)
        }
        return user
      })
      .catch(err => { throw err })
  }

  postLogout() {
    const token = TokenService.getSessionToken()
    const dataToken = { token: token}
    return api
      .post(url.POST_LOGOUT, dataToken)
      .then((response) => {
        const res = response.data.data
        if (res.logout === true) {
          TokenService.removeUser()
        }
        return response.data
      })
      .catch(err => { throw err })
  }

  postForgetPwd (data) {
    return api
      .post(url.POST_PASSWORD_FORGET, data)
      .then((response) => {
        if (response.status === 400 || response.status === 500)
          throw response.data;
        return response.data;
      })
      .catch((err) => {
        throw err[1];
      })
  }

  postRegister(data) {
    return api
      .post(url.POST_REGISTER, { ...data})
      .then((response) => {
        if (response.status >= 200 || response.status <= 299)
          return response.data;
        throw response.data;
      })
      .catch((err) => {
        var message;
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = 'Sorry! the page you are looking for could not be found';
              break;
            case 500:
              message =
                'Sorry! something went wrong, please contact our support team';
              break;
            case 401:
              message = 'Invalid credentials';
              break;
            default:
              message = err[1];
              break;
          }
        }
        throw message;
      })
  }

  getProfile() {
    return api
      .get(url.GET_ME)
  }
}

export default new AuthService()