import {
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAIL,
  ADD_BANNER_SUCCESS,
  ADD_BANNER_FAIL,
  ADD_IMAGE_BANNER_SUCCESS,
  ADD_IMAGE_BANNER_FAIL,
  UPDATE_STATUS_BANNER_SUCCESS,
  UPDATE_STATUS_BANNER_FAIL,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAIL,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
} from './actionTypes'

const INIT_STATE = {
  banners: {},
  error: {},
}

const banners = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload,
        loading: false,
      }

    case GET_BANNERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_BANNER_SUCCESS:
      return {
        ...state,
        banners: [...state, action.payload],
        loading: false,
      }

    case ADD_BANNER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case ADD_IMAGE_BANNER_SUCCESS:
      return {
        ...state,
        image: [...state, action.payload],
        loading: false,
      }

    case ADD_IMAGE_BANNER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_STATUS_BANNER_SUCCESS:
      return {
        ...state,
        banners: state.banners.map(banner =>
          banner.id.toString() === action.payload.id.toString()
            ? { banner, ...action.payload }
            : banner
        ),
      }

    case UPDATE_STATUS_BANNER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        banners: state.banners.map(banner =>
          banner.id.toString() === action.payload.id.toString()
            ? { banner, ...action.payload }
            : banner
        ),
      }

    case UPDATE_BANNER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        banners: state.banners.filter(
          banner => banner.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_BANNER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default banners
