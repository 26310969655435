
import cloudinary from 'cloudinary/lib/cloudinary'
class CloudService {
  imageUp(file, folder) {
    const formData = new FormData()
    const usr = process.env.REACT_APP_CLOUDINARY_USR
    formData.append("file", file)
    formData.append("upload_preset",process.env.REACT_APP_CLOUDINARY_PRESET)
    formData.append("cloud_name", usr)
    // formData.append("folder",folder)

    const url = `https://api.cloudinary.com/v1_1/${usr}/auto/upload`
    const options = {
      method: 'POST',
      body: formData,
    }
    return fetch(url,options)
  }

  imageDel(file_id) {
    cloudinary.config({
      cloud_name: process.env.REACT_APP_CLOUDINARY_USR,
      api_key: process.env.REACT_APP_CLOUDINARY_APIKEY,
      api_secret: process.env.REACT_APP_CLOUDINARY_APISECRET
    });

    return cloudinary.v2.uploader.destroy(file_id, function(error,result) {
      console.log(result, error) })
  }
}

export default new CloudService()
