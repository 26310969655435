import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { logoutUser } from 'store/actions'

class Logout extends Component {
  
  componentDidMount() {
    
    const { history } = this.props
    this.props.logoutUser(history)
  }

  render() {
    return null
  }
}

export default withRouter(connect(null, { logoutUser })(Logout))
