import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

// import { useDispatch } from 'react-redux'

import CategoriesList from './category-list'
import CategoryCreate from './category-create'

// import { getUserProfile, getCategories } from 'store/actions'

export const Categories = props => {
  const { match } = props
  const { path } = match
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(getUserProfile())
  // }, [dispatch])

  // useEffect(() => {
  //   dispatch(getCategories())
  // }, [dispatch])

  return (
    <>
      <Switch>
        {/* ARTICLES */}
        <Route path={`${path}/create`}>
          <CategoryCreate {...props} />
        </Route>
        <Route path={`${path}/:id/edit`}>
          <CategoryCreate {...props} />
        </Route>
        <Route path={`${path}/:id`}>{/* <UsersOverview {...props} /> */}</Route>

        <Route path={path} component={CategoriesList} {...props} />
      </Switch>
    </>
  )
}

export default Categories
