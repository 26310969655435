import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'

const EventPrevNext = props => {
  const { prev,next } = props
  return (
    <div className="pre-nxt-event">
      <Row>
        {prev?
        <Col md="6">
          <div className="next-prev-item prev-event d-flex">
            <div className="prev-img">
              <img
                src={prev.image_url}
                alt={prev.title}
              />
            </div>
            <div className="prev-text">
              <p>
                <Link to={process.env.PUBLIC_URL + '/eventos/' + prev.slug}>
                  {prev.excerpt}
                </Link>
              </p>
              <span> {prev.fecha}</span>
            </div>
          </div>
        </Col>
        :null}
        {next?
        <Col md="6">
          <div className="next-prev-item next-event d-flex flex-row-reverse text-right">
            <div className="next-img">
            <img
                src={next.image_url}
                alt={next.title}
              />
            </div>
            <div className="next-text">
              <p>
                <Link to={process.env.PUBLIC_URL + '/eventos/' + next.slug}>
                  {next.excerpt}
                </Link>
              </p>
              <span> {next.fecha}</span>
            </div>
          </div>
        </Col>
        :null}
      </Row>
    </div>
  )
}

export default EventPrevNext
