import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_EVENTS,
  ADD_NEW_EVENT,
  ADD_IMAGE_EVENT,
  UPDATE_STATUS_EVENT,
  DELETE_EVENT,
  UPDATE_EVENT
} from "./actionTypes"

import {
  getEventsSuccess,
  getEventsFail,
  addEventFail,
  addEventSuccess,
  addImageEventFail,
  addImageEventSuccess,
  changeStatusEventFail,
  changeStatusEventSuccess,
  updateEventSuccess,
  updateEventFail,
  deleteEventSuccess,
  deleteEventFail,
} from "./event.actions"

//Include Both Helper File with needed methods
import EventsService from "services/events/events.service"
import Swal from "sweetalert2"
import { toast } from "react-toastify"

function* fetchEvents() {
  try {
    const response = yield call(EventsService.getEvents)
    if (response.status === 200) {
      yield put(getEventsSuccess(response.data.data))
      
    }
  } catch (error) {
    yield put(getEventsFail(error.response))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error. Intente nuevamente más tarde.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* onAddNewEvent({ payload: { event, history } }) {
  try {
    const response = yield call(EventsService.addNewEvent, event)
    if (response.status === 201) {
      
      yield put(addEventSuccess(response.data.data))
      toast.success('Evento creado satisfactoriamente.')
    }
    
  } catch (error) {
    yield put(addEventFail(error))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error. Intente nuevamente más tarde.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* onAddImageEvent({ payload: { image, history } }) {
  console.log(image);
  try {
    const response = yield call(EventsService.addImageEvent, image)
    yield put(addImageEventSuccess(response.data.data))
    history.push('/events')
  } catch (error) {
    yield put(addImageEventFail(error))
  }
}

function* onCangeStatusEvent({ payload: { event, history, id} }) {
  try {
    const response = yield call(EventsService.changeStatus, id, event)
    if (response.status === 201) {
      yield put(changeStatusEventSuccess(response.data.data))
      toast.success('Event actualizado satisfactoriamente.')
    }
    
  } catch (error) {
    yield put(changeStatusEventFail(error.response))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* onUpdateEvent({ payload: { event, history, id } }) {
  try {
    const response = yield call(EventsService.updateEvent,id, event)
    if (response.status === 201) {
      yield put(updateEventSuccess(response.data.data))
      toast.success(`El Evento ${response.data.slug} fue actualizado satisfactoriamente.`)
    }
  } catch (error) {
    yield put(updateEventFail(error))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error. Intente nuevamente más tarde.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* onDeleteEvent({ payload: { eventId, history} }) {
  try {
    const response = yield call(EventsService.deleteEvent, eventId)
    if (response.status === 200) {
      yield put(deleteEventSuccess(response.data.data))
      Swal.fire({
        title: 'Eliminado!',
        text: 'Se ha eliminado el evento exitosamente.',
        icon: 'success',
        confirmButtonColor: '#62a086'
      })
    }
  } catch (error) {
    yield put(deleteEventFail(error.response))
    Swal.fire({
      title: 'Error!',
      text: 'Se ha producido un error.',
      icon: 'error',
      confirmButtonColor: '#62a086'
    })
  }
}

function* eventSaga() {
  yield takeEvery(GET_EVENTS, fetchEvents)
  yield takeEvery(ADD_NEW_EVENT, onAddNewEvent)
  yield takeEvery(ADD_IMAGE_EVENT, onAddImageEvent)
  yield takeEvery(UPDATE_STATUS_EVENT, onCangeStatusEvent)
  yield takeEvery(UPDATE_EVENT, onUpdateEvent)
  yield takeEvery(DELETE_EVENT, onDeleteEvent)
}

export default eventSaga
