//PROF_CATEGORIES
export const GET_COURSES_CATEGORIES_PUBLISHED = "/cursoscategory"
export const GET_NEWS_CATEGORIES_PUBLISHED = "/noticiascategory"
export const GET_EVENTS_CATEGORIES_PUBLISHED = "/eventoscategory"
export const GET_CATEGORIES = "/categories"

export const GET_CATEGORY_SLUG = "/categories"
export const ADD_NEW_CATEGORY = "/categories"
export const UPDATE_CATEGORY = "/categories"
export const DELETE_CATEGORY = "/categories"
