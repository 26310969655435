import { all, fork } from "redux-saga/effects"

// public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
// private
import UsersSaga from "./users/saga"
import BannersSaga from "./banners/banner.saga"
import CategoriesSaga from "./categories/category.saga"
import NewsSaga from './news/news.saga'
import CoursesSaga from "./courses/course.saga"
import EventsSaga from "./events/event.saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    AuthSaga(),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(UsersSaga),
    fork(BannersSaga),
    fork(CategoriesSaga),
    fork(NewsSaga),
    fork(CoursesSaga),
    fork(EventsSaga),
    LayoutSaga(),
  ])
}
