import React, { Component } from 'react'
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap'
import { Styles } from './styles/tabBox'

class TabBox extends Component {
  render() {
    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section">
          <Container>
            <Tab.Container defaultActiveKey="why">
              <Row>
                <Col lg="3" md="4">
                  <Nav className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="why">
                        <i className="las la-arrow-right"></i> Historia
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="vision">
                        <i className="las la-arrow-right"></i> Ideario
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="ranking">
                        <i className="las la-arrow-right"></i> Directivos
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg="9" md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="why">
                      <h4 className="tab-title">Historia</h4>

                      <p className="tab-desc">
                        La historia comienza en 1903, con la convocatoria del
                        Obispo de Santa Fe Monseñor Juan Agustín Boneo a la
                        Madre Eufrasia Iaconis, fundadora de la Congregación
                        Hijas de la Inmaculada Concepción de Buenos Aires, para
                        dirigir un Asilo Maternal cuyo objetivo sería: "velar
                        con solicitud verdaderamente maternal y material de los
                        niños del pueblo, amparándoles, educándoles según su
                        edad y proveyéndoles del alimento necesario durante las
                        horas en que sus padres, madres o encargados se
                        consagran a sus labores" (Estatutos del Asilo Maternal
                        de Santa Fe, 25/2/1903).
                      </p>
                      <p className="tab-desc">
                        Bajo la advocación de Nuestra Señora de Guadalupe y con
                        la atención de hermanas procedentes de Rosario y Buenos
                        Aires, el Asilo comienza a funcionar, en una calle
                        alquilada en calle San Jerónimo, para niños, de ambos
                        sexos, desde los 3 años en adelante.
                      </p>
                      <p className="tab-desc">
                        Al año siguiente, se presenta la posibilidad de adquirir
                        un terreno en calle 9 de julio, actual emplazamiento de
                        nuestra institución. El Gobernador Rodolfo Freyre
                        acuerda una buena suma para sufragar los gastos de
                        construcción del nuevo Asilo, que será sostenido por la
                        caridad pública, tal como reza en la convocatoria de los
                        diarios de la época solicitando donaciones y la ayuda de
                        la población santafesina.
                      </p>
                      <p className="tab-desc">
                        El 30 de abril de 1905 -con la presencia del Señor
                        Gobernador Freyre y su esposa, el Obispo Boneo, el
                        presbítero Gioda (capellán del Asilo), Madre Eufrasia y
                        su secretaria, Madre Estanislada Tognoni, y público en
                        general- se procede a la inauguración del nuevo
                        edificio.
                      </p>
                      <p className="tab-desc">
                        Ese mismo año, la Superiora de la casa, el Obispo y el
                        Gobernador resuelven disponer el establecimiento para
                        alojar niñas internas, sin descuidar la tarea de
                        enseñanza del Asilo.
                      </p>
                      <p className="tab-desc">
                        En septiembre de 1906, se adquiere el terreno anexo al
                        Asilo, con el esfuerzo de la Congregación, subvenciones
                        provenientes de los Gobiernos Nacional y Provincial y
                        donativos de personas particulares.
                      </p>
                      <p className="tab-desc">
                        Desde sus inicios y a lo largo del tiempo, nuestra
                        Institución sufre transformaciones:
                      </p>

                      <ul className="list-unstyled check-list">
                        <li className="wnd-align-justify">
                          <i className="fa fa-check"></i>Se acentúa la tarea
                          educativa con la institucionalización del Nivel
                          primario en 1938. El Asilo Maternal deja paso al Hogar
                          Maternal Nuestra Señora de Guadalupe que cuenta con
                          Jardín de Infantes, Preescolar y todos los grados del
                          nivel primario.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>En 1941, se inaugura el
                          Pensionado para estudiantes universitarias.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>1962, marca el inicio
                          del ciclo Secundario de Práctica Comercial con el
                          nombre Madre María Fabiano.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>En el año 1988, tanto
                          el Hogar Maternal (del nivel primario) como el Colegio
                          María Fabiano (del nivel secundario) cambian su nombre
                          a Nuestra Señora de Guadalupe, con el que se lo conoce
                          en nuestros días.
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="vision">
                      <h4 className="tab-title">Ideario</h4>
                      <h5 className="title">Fin</h5>
                      <p className="tab-desc">
                        Evangelizar a través de la enseñanza, inspirados en el
                        misterio de la Inmaculada Concepción.
                      </p>
                      <h5 className="title">Objetivos</h5>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Favorecer el orden y la
                          plenitud de todas las capacidades personales,
                          abarcando los aspectos: físico, afectivo, cognitivo,
                          social, ético y trascendente.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Orientar hacia el
                          conocimiento de sí mismo y del ideal personal,
                          procurando la necesaria madurez afectiva que capacite
                          para la vinculación efectiva y correcta con lugares,
                          cosas, comunidad, Dios.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lograr una concepción
                          armónica y jerárquica del saber, coronada por el
                          conocimiento de Dios y de su plan divino. Para ello:
                          <ul className="list-unstyled">
                            <li>
                              <i className="las la-arrow-right"></i>Enseñar las
                              asignaturas con espíritu científico en apertura a
                              la Verdad Total.
                            </li>
                            <li>
                              <i className="las la-arrow-right"></i>Procurar que los
                              alumnos adquieran los fundamentos de la cultura
                              universal, una cosmovisión realista y un
                              desarrollado juicio crítico
                            </li>
                          </ul>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Construir una comunidad
                          educativa cohesionada por un fin común, impregnada de
                          espíritu de trabajo y de vivencia religiosa y mariana.
                          En particular, favorecer la disposición para la vida
                          de gracia.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Promover el estudio y
                          propagación de la devoción a la Virgen María, en
                          especial del dogma de la INMACULADA CONCEPCIÓN.
                        </li>
                        
                      </ul>
                      <p className="tab-desc"><h6 >Concepción de la Educación:</h6> Es el proceso intencional por el cual, bajo la guía de padres y maestros,
la persona avanza hacia su plenitud por la adquisición de virtudes intelectuales y morales. Cuando
se abre a la acción del Maestro Interior, el Espíritu Santo, esa plenitud humana se reconoce como
santidad.</p>

<p className="tab-desc"><h6 >Concepción del Hombre:</h6> Es la creatura corpóreo espiritual, herida por el Pecado Original y redimida
por Cristo. Su dignidad está enraizada en haber sido creado a imagen y semejanza de Dios. Por la
razón es capaz de comprender el orden de las cosas establecido por el Creador. Por su voluntad es
capaz de dirigirse por sí misma a su bien verdadero. Encuentra su perfección en la búsqueda y el
amor a la verdad y el bien. <br />
Por su Pasión, Cristo le mereció la salvación, concediéndole la vida nueva en el Espíritu Santo.</p>

<p className="tab-desc"><h6 >Fin del hombre: </h6> Es la felicidad eterna, que en el contexto de la Fe conocemos como Cielo y
esperamos alcanzar por la gracia misericordiosa de Dios. Es la vida perfecta con la Santísima
Trinidad, la realización de las aspiraciones más profundas del hombre, el estado supremo y
definitivo de dicha. La vida de los bienaventurados consiste en la plena posesión de los frutos de laredención realizada por Cristo, que asocia a su glorificación celestial a quienes han creído en Él y
han permanecido fieles a su Voluntad.</p>

<p className="tab-desc"><h6 >Alumno FIC:</h6> Niño o joven, protagonista del propio crecimiento, que necesita ayuda y apoyo en su
proceso formativo En los más pequeños, la conciencia de esta necesidad es expresada por sus padres
o responsables; en los mayores, debe estar personalmente asumida. Los alumnos están llamados a
intervenir activamente en la vida de nuestros colegios, según su edad y condiciones, y adquirir
responsabilidades proporcionadas.</p>
<p className="tab-desc"><h6 >Nuestro estilo educativo:</h6> Se inspira en la pedagogía de Cristo y de María. Pedagogía de Cristo: parte
de la realidad para satisfacer las necesidades más profundas del ser humano; emplea siempre y sólo
la verdad; conduce con suavidad y es justo y firme en la corrección; su alimento es hacer la
Voluntad del Padre. Pedagogía de María: sostenida en la contemplación, se derrama en servicio al
prójimo y alabanza al Señor. Su fidelidad culmina en el martirio y su maternidad nos engendra
para Cristo. Las comunidades educativas de nuestros Colegios son conscientes de la necesidad de
nutrirse y confrontarse continuamente con las fuentes de donde deriva su razón de ser: la Palabra
salvadora de Dios en la Sagrada Escritura y la Tradición, iluminadas por el Magisterio de la Iglesia.</p>
<p className="tab-desc"><h6 >Nuestras prioridades:</h6> En cuanto a las necesidades de los otros, lo primero es la atención de la pobreza
y la orfandad, que se manifiestan de variadas formas, según lugares y tiempos. En cuanto a lo que
ofrecen nuestros colegios, lo primero es dar a conocer a Cristo, a través de María, como enseña la
Iglesia. Por ello, la Catequesis y la Liturgia poseen un lugar de privilegio entre las diversas
actividades formativas. La enseñanza tiene por norte la verdad. De aquí se desprende la
organización curricular.</p>
<p className="tab-desc"><h6 >Nuestra relación con las familias:</h6> Sostener a los padres en su acción educadora; responder a la
delegación que ellos hacen con amor y fidelidad. Resulta evidente que las familias, al buscar
libremente la Institución, aceptan el Ideario y apoyan su instrumentación a través de los agentes
institucionales.</p>
<p className="tab-desc"><h6 >Nuestra relación con la comunidad:</h6> Ad intra: fraternidad, cooperación, apoyo mutuo, confianza,
respeto. Como en la familia humana, la comunidad educativa se genera en torno a una Madre, que
confiere “alma” a la convivencia. La Virgen María, además de modelo y paradigma de la
humanidad, es artífice de comunión. Ad extra: apostolado, solidaridad, compromiso, generosidad,
buen ejemplo. Siempre con la mirada puesta en María, la gran misionera, continuadora de la
Misión de su Hijo y formadora de misioneros.</p>
<hr />
<p className="tab-desc"> También, puede visualizarse el “Ideario de los Colegios de las Hijas de la Inmaculada Concepción de
Buenos Aires” en el siguiente enlace: <i className="fa fa-file-pdf-o"></i><a target="blank" href="https://www.fic.org.ar/wp-content/uploads/2019/10/Ideario-FIC.pdf">Ideario FIC</a>.</p>
<br />
<p className="tab-desc"> Descargar ficha de adhesión al Ideario Santa Fe: <i className="fa fa-file-pdf-o"></i><a target="blank" href={'/assets/files/IdearioFIC_SFe.pdf'}>Ideario FIC</a>.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="ranking">
                      <h4 className="tab-title">Autoridades</h4>
                      <p className="tab-desc"></p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Representante Legal:{' '}
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Directivos Nivel
                          Primario:
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Directivos Nivel
                          Secundario:
                        </li>
                      </ul>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </section>
      </Styles>
    )
  }
}

export default TabBox
