import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

import AuthService from 'services/auth/auth.service'

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "api") {
      const response = yield call(AuthService.postRegister, user)
      yield put(registerUserSuccessful(response))
    }

  } catch (error) {
    let message;
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 404:
          message = 'Lo sentimos! La página que busca no existe.';
          break;
        case 500:
          message =
            'Lo sentimos! Algo salió mal, por favor ponte en contacto con nuuestro equipo.';
          break;
        case 401:
          message = 'Credenciales inválidas';
          break;
        case 422:
          message = 'Datos inválidos, verifique los datos cargados.' +  error.response.data.errors.email;
          break;
        default:
          message = error[1];
          break;
      }
    }
    yield put(registerUserFailed(message))
  // }

    // // Error
    // if (error.response) {
    //     // The request was made and the server responded with a status code
    //     // that falls out of the range of 2xx
    //     // console.log(error.response.data);
    //     // console.log(error.response.status);
    //     // console.log(error.response.headers);
    // } else if (error.request) {
    //     // The request was made but no response was received
    //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //     // http.ClientRequest in node.js
    //     console.log(error.request);
    // } else {
    //     // Something happened in setting up the request that triggered an Error
    //     console.log('Error', error.message);
    // }
    // console.log(error.config);
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
