import api from 'config/api'
import TokenService from './auth/token.service'
import * as url from './auth/url_helper'

const setup = (store) => {
  api.interceptors.request.use(
    (config) => {
      const token = TokenService.getSessionToken()
      if (token) {
        // For Spring Boot back-end
        config.headers["Authorization"] = 'Bearer ' + token
        // for Node.js Express back-end
        // config.headers["x-access-token"] = token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  const { dispatch } = store
  api.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== url.POST_LOGIN && err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            const rS = await api.post(url.POST_REFRESH_TOKEN, {
              token: TokenService.getSessionRefreshToken()
            })

            const { token } = rS.data

            TokenService.updateSessionToken(token)
            dispatch(TokenService.refreshToken(token))

            return api(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    }
  )
}

export default setup
